import { PayoutService } from '../../helpers/backend/payouts.service';

import {
  FETCH_PAYOUT_LIST,
  // TOGGLE_PAYOUTS_LOADER,
  CREATE_PAYOUTS,
  APPROVE_PAYOUTS,
  APPROVE_PAYOUTS_VIA_EMAIL,
  VERIFY_PAYOUTS_VIA_EMAIL,
  GENERATE_OTP_PAYOUT,
  GENERATE_OTP_PAYOUT_VIA_EMAIL,
  GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY,
  RESEND_OTP_PAYOUT_VIA_EMAIL,
  RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY,
  PAYOUT_FILE_UPLOAD,
  FETCH_PAYOUT_LIST_MASTER_MERCHANT,
  UPDATE_PAYOUT_STATUS,
  UPDATE_PAYOUTS,
  EXPORT_PAYOUTS_INTO_EXCEL,
  FETCH_PAYOUT_DETAILS
} from '../actions.type';

import {
  UPDATE_PAYOUTS_DATA_IN_STORE,
  APPROVE_PAYOUTS_DATA_IN_STORE,
  GENERATE_OTP_PAYOUT_IN_STORE,
  TOGGLE_PAYOUTS_LOADER_IN_STORE,
  ADD_PAYOUT_DETAILS_IN_STORE
} from '../mutations.type';

export const state = {
  isFetchingData: false,
  content: [],
  total_elements: 0,
  total_pages: 0,
  size: 0,
  page: 0,
  number_of_elements: 0,
  detailsContent:{}
}

export const mutations = {
  [TOGGLE_PAYOUTS_LOADER_IN_STORE](state, toggle){
    state.isFetchingData = toggle;
  },
  [UPDATE_PAYOUTS_DATA_IN_STORE](state, payoutdata) {
    // TODO: @VISHAL please check this
    // state.content = cloneDeep(payoutdata.content)
    // state.number_of_elements = payoutdata.number_of_elements;
    state.content = payoutdata.content
    state.total_elements = payoutdata.total_elements
    state.total_pages = payoutdata.total_pages
    state.size = payoutdata.size
    state.page = payoutdata.page
    state.number_of_elements = payoutdata.number_of_elements
  },
  [APPROVE_PAYOUTS_DATA_IN_STORE](state, payoutdata) {
    console.log({ state, payoutdata })
  },
  [GENERATE_OTP_PAYOUT_IN_STORE](state, payoutdata) {
    console.log({ state, payoutdata })
  },
  [ADD_PAYOUT_DETAILS_IN_STORE](state, payoutDeatilsData) {
		state.detailsContent = payoutDeatilsData;
	},
}

export const getters = {
  getPayoutList(state) {
    return state.payoutdata;
  }
}

export const actions = {
  [FETCH_PAYOUT_LIST](context, postData) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      context.commit(TOGGLE_PAYOUTS_LOADER_IN_STORE, true);
      postData['environment'] = environment;
      PayoutService.fetchPayoutLists(key, postData).then((response) => {
        context.commit(TOGGLE_PAYOUTS_LOADER_IN_STORE, false);
        context.commit(UPDATE_PAYOUTS_DATA_IN_STORE, response);
        resolve(response)
      }).catch((error) => {
        context.commit(TOGGLE_PAYOUTS_LOADER_IN_STORE, false);
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [FETCH_PAYOUT_LIST_MASTER_MERCHANT](context, postData) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      context.commit(TOGGLE_PAYOUTS_LOADER_IN_STORE, true);
      postData['environment'] = environment;
      PayoutService.fetchPayoutListMasterMerchant(key, postData).then((response) => {
        context.commit(TOGGLE_PAYOUTS_LOADER_IN_STORE, false);
        context.commit(UPDATE_PAYOUTS_DATA_IN_STORE, response.content);
        resolve(response)
      }).catch((error) => {
        context.commit(TOGGLE_PAYOUTS_LOADER_IN_STORE, false);
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [PAYOUT_FILE_UPLOAD](context, { fileData }) {
    const iamportKey = context.rootState.auth.currentUser.iamportKey;
    const environment = context.rootState.auth.environment;
    fileData['environment'] = environment;
    fileData.append('environment', environment);
    return new Promise((resolve, reject) => {
      PayoutService.uploadFile(iamportKey, fileData).then((response) => {
        resolve(response);
      }).catch(reject);
    })
  },

  [CREATE_PAYOUTS](context, newPayouts) {
    return new Promise((resolve, reject) => {
      newPayouts.key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      newPayouts['environment'] = environment;
      PayoutService.createPayouts(newPayouts).then((response) => {
        context.commit('notification/success', "Payouts Account Created Successfully", { root: true })
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [UPDATE_PAYOUTS](context, newPayouts) {
    return new Promise((resolve, reject) => {
      newPayouts.key = context.rootState.auth.currentUser.iamportKey;
      newPayouts.environment = context.rootState.auth.environment;

      PayoutService.updatePayouts(newPayouts).then((response) => {
        context.commit('notification/success', "Payout Updated Successfully", { root: true })
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  // Approve email endpoints via Mobile number
  [APPROVE_PAYOUTS](context, { mobileNo, approveData }) {
    console.log("test");
    return new Promise((resolve, reject) => {
      approveData.approver_name = context.rootState.auth.currentUser.name;
      const environment = context.rootState.auth.environment;
      approveData['environment'] = environment;
      PayoutService.approvePayouts({ mobileNo, approveData }).then((response) => {
        context.dispatch(`${FETCH_PAYOUT_LIST}`, {
          from: "2006-01-02 15:04:05",
          to: "2024-01-02 15:04:05",
          page: 1, pagesize: 10,
          filters: {},
        })
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [APPROVE_PAYOUTS_VIA_EMAIL](context, { approveData }) {
    console.log("test");
    return new Promise((resolve, reject) => {
      approveData['approver_name'] = context.rootState.auth.currentUser.name;
      approveData['approver_email'] = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      approveData['environment'] = environment;
      PayoutService.approvePayoutsViaEmail(approveData).then((response) => {
        context.dispatch(`${FETCH_PAYOUT_LIST}`, {
          from: "2006-01-02 15:04:05",
          to: "2024-01-02 15:04:05",
          page: 1, pagesize: 10,
          filters: {},
        })
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [VERIFY_PAYOUTS_VIA_EMAIL](context, { approveData }) {
    console.log("test");
    return new Promise((resolve, reject) => {
      const environment = context.rootState.auth.environment;
      approveData['name'] = context.rootState.auth.currentUser.name;
      approveData['email'] = context.rootState.auth.currentUser.email_address;
      approveData['environment'] = environment;
      PayoutService.verifyPayoutsViaEmail(approveData).then((response) => {
        context.dispatch(`${FETCH_PAYOUT_LIST}`, {
          from: "2006-01-02 15:04:05",
          to: "2024-01-02 15:04:05",
          page: 1, pagesize: 10,
          filters: {},
        })
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [GENERATE_OTP_PAYOUT](context, mobileNo) {
    return new Promise((resolve, reject) => {
      PayoutService.generateOtp(mobileNo).then((response) => {
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [GENERATE_OTP_PAYOUT_VIA_EMAIL](context) {
    return new Promise((resolve, reject) => {
      const merchantEmail = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      PayoutService.generateOtpViaEmail(merchantEmail, environment).then((response) => {
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY](context) {
    return new Promise((resolve, reject) => {
      const merchantEmail = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      PayoutService.generateOtpViaEmailForVerify(merchantEmail, environment).then((response) => {
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [RESEND_OTP_PAYOUT_VIA_EMAIL](context) {
    return new Promise((resolve, reject) => {
      const merchantEmail = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      PayoutService.resentOtpViaEmail(merchantEmail, environment).then((response) => {
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY](context) {
    return new Promise((resolve, reject) => {
      const merchantEmail = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      PayoutService.resentOtpViaEmailForVerify(merchantEmail, environment).then((response) => {
        resolve(response);
        context.commit('notification/success', "successful", { root: true })
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [UPDATE_PAYOUT_STATUS](context, linkRef) {
    return new Promise((resolve, reject) => {
      PayoutService.updatePayoutStatus(linkRef).then((response) => {
        context.commit('notification/success', "Payout Cancelled successfully", { root: true })
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
  [EXPORT_PAYOUTS_INTO_EXCEL](context, postData) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      postData['environment'] = environment;
      const isMasterMerchant = context.rootState.auth.currentUser.is_master_merchant;
      PayoutService.exportPayoutData(key, postData, isMasterMerchant).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
	[FETCH_PAYOUT_DETAILS]({commit , rootState}, { ref }) {
		const iamportKey =  rootState.auth.currentUser.iamportKey;
		const environment = rootState.auth.environment;
		return new Promise((resolve, reject) => {
			PayoutService.getPayouteDetailsList(iamportKey, environment, ref).then((response) => {
				commit(ADD_PAYOUT_DETAILS_IN_STORE, response)
				resolve(response);
			})
			.catch(reject);
		});
	},
}

export const namespaced = true;