import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const SmartRoutingService = {
  fetchTransactionRoutes,
  postRouteDetails,
  updateRouteDetails,
  deleteRouteDetails,
  fetchAllPaymentChannels,
  setDefaultRouteDetails
}

function fetchTransactionRoutes(iamportKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({ environment });
  return fetch(`${CHAIPAY_API_URL}/merchants/txn-router/${iamportKey}/routes?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function postRouteDetails(payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/txn-router/create`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateRouteDetails(iamportKey, payload, routingRef) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/txn-router/${iamportKey}/update/${routingRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function deleteRouteDetails(iamportKey, routingRef) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/txn-router/${iamportKey}/delete/${routingRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchAllPaymentChannels(merchantUuid, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ environment, "pmt_type": "PAYIN" }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUuid}/list-pay-channels`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function setDefaultRouteDetails(iamportKey, routingRef) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/txn-router/${iamportKey}/default/${routingRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}