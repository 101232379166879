import moment from "moment";
import Headers from '../headers'

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const TransactionService = {
  getTransactionListForMerchant,
  resendWebhookDataByTransactionId,
  getChannelListForMerchant,
  fetchWebhookDataByTransactionId,
  fetchFailoverData,
  exportTransactionDataForMerchant,
  fetchTransactionDetails,
  updateTransactionStatus,
  uploadTransactionFile,
  getColumnListForMerchant,
  updateColumnListForMerchant,
  resetColumnListForMerchant
};

function getTransactionListForMerchant(merchantUUID, iamportKey, data) {
  const URI = data.isMasterMerchant ? `master-merchant/${iamportKey}/merchants/transactions` : `merchant/${merchantUUID}/payments`;
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(data.payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getColumnListForMerchant(subUserUuid, iamportKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/subUser/${subUserUuid}/custom-col-details?${queryParams}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateColumnListForMerchant(subUserUuid, iamportKey, payload) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  const queryParams = new URLSearchParams({environment: payload.environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/subUser/${subUserUuid}/custom-col-details?${queryParams}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function resetColumnListForMerchant(subUserUuid, iamportKey, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include'
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/subUser/${subUserUuid}/reset-custom-col-config?${queryParams}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getChannelListForMerchant(merchantUUID, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/list-paychannels?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchWebhookDataByTransactionId(merchantUUID, iamportKey, orderRef) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/${iamportKey}/webhookdata/${orderRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchFailoverData(iamportKey, merchantOrderRef) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/txn-router/failover/${iamportKey}/get-txn-details/${merchantOrderRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function resendWebhookDataByTransactionId(merchantUUID, iamportKey, orderRef) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({order_ref: orderRef}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/send-webhook`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function exportTransactionDataForMerchant(merchantUUID, iamportKey, filters, sorting_data, isMasterMerchant, merchant_uuids, environment, from_Date, to_Date) {
  if(filters == undefined) filters = {};
  const toDate = !to_Date || to_Date === "" ? new Date() : to_Date;
  const fromDate = !from_Date || from_Date === "" ? new Date('2006-01-02') : from_Date;
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.key;
      });
    }else{
      uuidsToSend = [];
    }
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify({environment, to: toDate, from: fromDate, filters: filters != undefined ? filters: {}, sorting_data, merchant_keys:uuidsToSend}),
      credentials: 'include',
    };
    
    return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants/export-transactions`, requestOptions).then((response) => {
      if (response.ok) {
        return response.blob().then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "transactions-data_" + (new Date().toISOString()) + ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
  }else{
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify({environment, to: moment(toDate).format('YYYY-MM-DD HH:mm:ss'), from: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), filters: filters != undefined ? filters: {}, sorting_data}),
      credentials: 'include',
    };
  
    return fetch(`${CHAIPAY_API_URL}/merchant/${merchantUUID}/export-payments`, requestOptions).then((response) => {
      if (response.ok) {
        return response.blob().then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "transactions-data_" + (new Date().toISOString()) + ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
  }
}

function fetchTransactionDetails(merchantUUID, iamportKey, orderRef) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ order_ref: orderRef }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/${iamportKey}/transaction-details`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateTransactionStatus(merchantUUID, data) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(data),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/transaction/status`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function uploadTransactionFile(fileData,iamportKey) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(false), 'X-Chaipay-Client-Key': iamportKey },
    body: fileData,
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/third_party/transactions`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}