import { DashboardRemittancesService } from '../../helpers/backend/dashboard_remittances.service';
import {
  FETCH_REMITTANCES_TRANSACTION_STATS,
  // FETCH_TRANSACTION_VOLUME,
  // FETCH_TRANSACTION_VOLUME_SEASONALITY,
  FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR,
  FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS,
} from '../actions.type';
import {
    ADD_TRANSACTION_STATS_IN_STORE,
    // ADD_TRANSACTION_VOLUME_IN_STORE,
    // ADD_TRANSACTION_SEASONALITY_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE,
    ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CURR_IN_STORE,
    ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CHANNE_IN_STORE,
    ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_METHOD_IN_STORE
} from '../mutations.type';

export const state = {
  txn_stats:  {},
  // txn_vol:  { labels: [], datasets: [] },
  // txn_vol_seasonality:  { labels: [], datasets: [] },
  txn_vol_by_status:  { labels: [], datasets: [] },
  txn_vol_by_pmt_channel:  { labels: [], datasets: [] },
  txn_vol_by_pmt_method:  { labels: [], datasets: [] },
  txn_vol_stacked_bar:  { labels: [], datasets: [] },
  txn_vol_stacked_bar_pmt_channel:  { labels: [], datasets: [] },
  txn_vol_stacked_bar_pmt_method:  { labels: [], datasets: [] },
}

export const mutations = {
  [ADD_TRANSACTION_STATS_IN_STORE](state, stats) {
    state.txn_stats = stats
  },
  // [ADD_TRANSACTION_VOLUME_IN_STORE](state, txnVol) {
  //   state.txn_vol = txnVol;
  // },
  // [ADD_TRANSACTION_SEASONALITY_IN_STORE](state, txnVolSeasonality) {
  //   state.txn_vol_seasonality = txnVolSeasonality;
  // },
  [ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE](state, txnVolByStatus) {
    state.txn_vol_by_status = txnVolByStatus;
  },
  [ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE](state, txnVolByChannel) {
    state.txn_vol_by_pmt_channel = txnVolByChannel;
  },
  [ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE](state, txnVolByMethod) {
    state.txn_vol_by_pmt_method = txnVolByMethod;
  },
  // [ADD_TRANSACTION_STACKED_BAR_IN_STORE](state, txnVolForStackedBar) {
  //   state.txn_vol_stacked_bar = txnVolForStackedBar;
  // },
  [ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CURR_IN_STORE](state, txnVolForStackedBar) {
    state.txn_vol_stacked_bar = txnVolForStackedBar;
  },
   [ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CHANNE_IN_STORE](state, txnVolForStackedBar) {
    state.txn_vol_stacked_bar_pmt_channel = txnVolForStackedBar;
  },
   [ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_METHOD_IN_STORE](state, txnVolForStackedBar) {
    state.txn_vol_stacked_bar_pmt_method = txnVolForStackedBar;
  },
  
}

export const getters = {}

export const actions = {

  [FETCH_REMITTANCES_TRANSACTION_STATS](context, {filter, from_date, to_date, isMasterMerchant, merchant_uuids, currency} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.iamportKey;
      // TODO: Remove exception for 'years' filter
      if (filter == 'years') {
        filter = 'months';
      }
      const environment = context.rootState.auth.environment;
      DashboardRemittancesService.fetchRemittancesTransactionsStats(merchantUUID, filter, from_date, to_date,isMasterMerchant, merchant_uuids, environment, currency).then((stats) => {
        context.commit(ADD_TRANSACTION_STATS_IN_STORE, stats)
        resolve(stats);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS](context, {filter, from_date, to_date, isMasterMerchant, merchant_uuids, currency} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      DashboardRemittancesService.fetchRemittancesTransactionVolForAllPieCharts(merchantUUID, filter, from_date, to_date, isMasterMerchant, merchant_uuids, environment, currency).then((txnData) => {
        context.commit(ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE, txnData.content.txnMetricsByChannel)
        context.commit(ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE, txnData.content.txnMetricsByMethod)
        context.commit(ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE, txnData.content.txnMetricsByCurrency)
        resolve(txnData);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  // [FETCH_TRANSACTION_VOLUME](context, {filter, isMasterMerchant, merchant_uuids, currency} = {}) {
  //   return new Promise((resolve, reject) => {
  //     const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
  //     const environment = context.rootState.auth.environment;
  //     DashboardService.fetchTransactionVolume(merchantUUID, filter, isMasterMerchant, merchant_uuids, environment, currency).then((response) => {
  //       context.commit(ADD_TRANSACTION_VOLUME_IN_STORE, response.txnVolume)
  //       resolve(response.txnVolume);
  //     }).catch((error) => {
  //       console.log('txnVolume error', error)
  //       const errorObject = JSON.parse(error.message)
  //       context.commit('notification/error', errorObject.Message, { root: true })
  //       reject(error);
  //     })
  //   });
  // },

  // [FETCH_TRANSACTION_VOLUME_SEASONALITY](context, {filter, isMasterMerchant, merchant_uuids, currency} = {}) {
  //   return new Promise((resolve, reject) => {
  //     const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
  //     const environment = context.rootState.auth.environment;
  //     DashboardService.fetchTransactionSeasonality(merchantUUID, filter, isMasterMerchant, merchant_uuids, environment, currency).then((response) => {
  //       context.commit(ADD_TRANSACTION_SEASONALITY_IN_STORE, response.txnSeasonality)
  //       resolve(response.txnSeasonality);
  //     }).catch((error) => {
  //       console.log('txnSeasonality error', error)
  //       const errorObject = JSON.parse(error.message)
  //       context.commit('notification/error', errorObject.Message, { root: true })
  //       reject(error);
  //     })
  //   });
  // },

  [FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR](context, {filter, from_date, to_date, isMasterMerchant, merchant_uuids, currency} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      DashboardRemittancesService.fetchRemittancesTransactionStackedBar(merchantUUID, filter, from_date, to_date, isMasterMerchant, merchant_uuids, environment, currency).then((response) => {
        // context.commit(ADD_TRANSACTION_STACKED_BAR_IN_STORE, response.chartData)
        context.commit(ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CURR_IN_STORE, response.curChartData)
        context.commit(ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CHANNE_IN_STORE, response.pcChartData)
        context.commit(ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_METHOD_IN_STORE, response.pmChartData)
        resolve(response);
      }).catch((error) => {
        console.log('txnStackedBar error', error)
        //TODO: REMOVE THIS
        // context.commit(ADD_TRANSACTION_STACKED_BAR_IN_STORE, undefined)
        context.commit(ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CURR_IN_STORE, undefined)
        context.commit(ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_CHANNE_IN_STORE, undefined)
        context.commit(ADD_REMITTANCES_TRANSACTION_STACKED_BAR_BY_METHOD_IN_STORE, undefined)
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
}

export const namespaced = true;
