export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import Headers from '../headers';

export const RefundService = {
  createRefundForTransaction,
  fetchRefundDetailsForTransaction,
  updateRefundStatus
};

function createRefundForTransaction(transactionId, refundDetails) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(refundDetails),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/refund/createRefund/${transactionId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchRefundDetailsForTransaction(transactionId) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/refund/getDataByTransaction/${transactionId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateRefundStatus(payload) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/refund/status`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
