import moment from "moment";
import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const RemittanceSummaryService = {
  getremittanceSummaryList,
  exportremittanceSummary
};

function getremittanceSummaryList(merchantUUId, iamportKey, pageIndex, pagesize, filters, toDate, fromDate, environment) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify({environment,"key":iamportKey, page: pageIndex, to: moment(toDate).format('YYYY-MM-DD HH:mm:ss'), from: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), page_size: pagesize, filters: filters}),
      credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/cross-border/settlement/remittance`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function exportremittanceSummary(merchantUUId, iamportKey, pageIndex, pagesize, filters, toDate, fromDate, environment) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify({environment,"key":iamportKey, page: pageIndex, to: moment(toDate).format('YYYY-MM-DD HH:mm:ss'), from: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), page_size: pagesize, filters: filters}),
      credentials: 'include',
    };
  
    return fetch(`${CHAIPAY_API_URL}/merchants/cross-border/settlement/export-remittance`, requestOptions).then((response) => {
      if (response.ok) {
        return response.blob().then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "remittance-summary-data_" + (new Date().toISOString()) + ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}