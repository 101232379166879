export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import Headers from '../headers'

export const AuthService = {
  logIn,
  register,
  resetPassword,
  sendResetPasswordLinkToEmail,
  sendEmailVerificationLink
};

function logIn(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/account/login`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

// TODO: Add input params in object
function register(bodyToSend) {
  
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyToSend),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/account/register`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

function sendResetPasswordLinkToEmail(emailId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ emailId }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/account/send-reset-password-link`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

function sendEmailVerificationLink(emailId) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ emailId }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/account/send-verify-email-link`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

function resetPassword(resetToken, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ resetToken, password }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/account/update-password`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}