<template>
  <div id="app">
    <router-view />
    <notifications
      position="top center"
      :duration="5000"
      :speed="500"
      style="margin-top: 80px;"
      :closeOnClick="false"/>
  </div>
</template>

<script>
// import appConfig from "@/app.config";
import {
  // FETCH_MERCHANT_ENTITY_DETAILS,
} from "@/state/actions.type";

export default {
  name: "app",
  // page: {
  //   // All subcomponent titles will be injected into this template.
  //   titleTemplate(title) {
  //     title = typeof title === "function" ? title(this.$store) : title;
  //     return title ? `${title} | ${appConfig.title}` : appConfig.title;
  //   }
  // },
  computed: {
    currentUser() {
      return this.$store.state.auth.currentUser;
    }
  },
  created() {
    if(this.currentUser && window.clarity) {
      window.clarity("identify", this.currentUser.email_address, this.currentUser.iamportKey)
    }
  },
  mounted() {
    // const currentUser = JSON.parse(localStorage.getItem('auth.currentUser'))
    // if (currentUser && currentUser.merchantUuid) {
    //   this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`)
    // }
  },
};
</script>