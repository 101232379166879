import Swal from "sweetalert2";
const Common = {
    fileValdation,
    multipleFileValdation,
    excelFileValdation,
    currencyByCountry,
    countryByCurrency,
    countryList,
    currencyList,
    flagByCountry,
    flagByCurrency
}
  
function fileValdation(file) {
    const size = (file.size / 1024 / 1024).toFixed(2);

    if (size > 20) {
        Swal.fire('error', `Maximum 20 MB file is allowed`)
        return false;
    }

    const allowedExtensions = /(\.jpg|\.png|\.svg|\.jpeg)$/i;

    if (!allowedExtensions.exec(file.name)) {
        Swal.fire('error', `Uploaded File is invalid`)
        return false;
    }

    return true;
}

function multipleFileValdation(files) {
    let isInvalid = true;
    const allowedExtensions = /(\.pdf|\.jpg|\.png|\.svg|\.jpeg)$/i;

    files.forEach(file => {
        const size = (file.size / 1024 / 1024).toFixed(2);
        if (size > 20 || !allowedExtensions.exec(file.name)) {
            isInvalid = false;
        }
    });

    return isInvalid;
}

function excelFileValdation(file, inputSize = 20, validateSize = true, allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i) {
    if(validateSize) {
        const size = (file.size / 1024 / 1024).toFixed(2);

        if(inputSize && size > inputSize) {
            Swal.fire('error', `Maximum ${inputSize} MB file is allowed`);
            return false;
        }
    }

    if (!allowedExtensions.exec(file.name)) {
        Swal.fire('error', `Uploaded File is invalid`)
        return false;
    }

    return true;
}


function currencyByCountry(country) {
    const currencies = {
        VN: "VND",
        TH: "THB",
        SG: "SGD",
        ID: "IDR",
        PH: "PHP",
        HK: "HKD",
        MY: "MYR",
        TW: "TWD",
        US: "USD",
        EU: "EUR",
        JP: "JPY",
        KR: "KRW",
        AU: "AUD",
        KH: "KHR",
        WS: "WST",
        AE: "AED",
        IN: "INR",
        KE: "KES",
        ZA: "ZAR",
        NG: "NGN",
        PL: "PLN",
        BE: "EUR",
        NL: "EUR",
        NZ: "NZD",
    };

    return currencies[country] || "USD";
}

function countryByCurrency(currency) {
    const countries = {
        VND: "VN",
        THB: "TH",
        SGD: "SG",
        IDR: "ID",
        PHP: "PH",
        HKD: "HK",
        MYR: "MY",
        TWD: "TW",
        USD: "US",
        EUR: "EU",
        JPY: "JP",
        KRW: "KR",
        AUD: "AU",
        KHR: "KH",
        WST: "WS",
        AED: "AE",
        INR: "IN",
        KES: "KE",
        ZAR: "ZA",
        NGN: "NG",
        PLN: "PL",
        CAD: "CA",
        NZD: "NZ"
    };

    return countries[currency] || "";
}
function countryList() {
    return ['AE', 'AU', 'BE', 'CA', 'CN', 'EU', 'GB', 'HK', 'ID', 'IN', 'JP', 'KE', 'KH', 'KR', 'MN', 'MY', 'NG', 'NL', 'NZ', 'PH', 'PL', 'SG', 'TH', 'TW', 'US', 'VN', 'WS', 'ZA'];
}

function currencyList() {
    return ['AED', 'AUD', 'CAD', 'CNY', 'EUR', 'GBP', 'HKD', 'IDR', 'INR', 'JPY', 'KES', 'KHR', 'KRW', 'MYR', 'NGN', 'NZD', 'PHP', 'PLN', 'SGD', 'THB', 'TWD', 'USD', 'VND', 'WST', 'ZAR'];
}

function flagByCountry() {
    const flags = {
        'ID': require('@/assets/images/flags/indonesia.png'),
        'PH': require('@/assets/images/flags/philippines.png'),
        'SG': require('@/assets/images/flags/singapore.png'),
        'TH': require('@/assets/images/flags/thailand.jpeg'),
        'VN': require('@/assets/images/flags/vietnam1.png'),
        'MY': require('@/assets/images/flags/malaysia.svg'),
        'TW': require('@/assets/images/flags/taiwan.png'),
        'HK': require('@/assets/images/flags/hong_kong.svg'),
        'KH': require('@/assets/images/flags/cambodia.svg'),
        'WS': require('@/assets/images/flags/samoa.svg'),
        'US': require('@/assets/images/flags/united_states.svg'),
        'GB': require('@/assets/images/flags/uk.svg'),
        'JP': require('@/assets/images/flags/japan.svg'),
        'KR': require('@/assets/images/flags/korea.svg'),
        'AU': require('@/assets/images/flags/australia.svg'),
        'AE': require('@/assets/images/flags/uae.svg'),
        'MN': require('@/assets/images/flags/mongolia.svg'),
        'IN': require('@/assets/images/flags/india.svg'),
        'KE': require('@/assets/images/flags/Kenya.png'),
        'ZA': require('@/assets/images/flags/south_africa.png'),
        'NG': require('@/assets/images/flags/nigeria.png'),
        'PL': require('@/assets/images/flags/poland.png'),
        'EU': require('@/assets/images/flags/europe.svg'),
        'BE': require('@/assets/images/flags/belgium.svg'),
        'NL': require('@/assets/images/flags/netherlands.svg.webp'),
        'NZ': require('@/assets/images/flags/newzealand.png'),
        'CA': require('@/assets/images/flags/canada.svg'),
        'CN': require('@/assets/images/flags/china.svg'),
    }
    return flags;
}

function flagByCurrency() {
    const flags = {
        'IDR': require('@/assets/images/flags/indonesia.png'),
        'PHP': require('@/assets/images/flags/philippines.png'),
        'SGD': require('@/assets/images/flags/singapore.png'),
        'THB': require('@/assets/images/flags/thailand.jpeg'),
        'VND': require('@/assets/images/flags/vietnam1.png'),
        'MYR': require('@/assets/images/flags/malaysia.svg'),
        'TWD': require('@/assets/images/flags/taiwan.png'),
        'HKD': require('@/assets/images/flags/hong_kong.svg'),
        'KHR': require('@/assets/images/flags/cambodia.svg'),
        'WST': require('@/assets/images/flags/samoa.svg'),
        'USD': require('@/assets/images/flags/united_states.svg'),
        'GBP': require('@/assets/images/flags/uk.svg'),
        'EUR': require('@/assets/images/flags/europe.svg'),
        'JPY': require('@/assets/images/flags/japan.svg'),
        'KRW': require('@/assets/images/flags/korea.svg'),
        'AUD': require('@/assets/images/flags/australia.svg'),
        'AED': require('@/assets/images/flags/uae.svg'),
        'INR': require('@/assets/images/flags/india.svg'),
        'KES': require('@/assets/images/flags/Kenya.png'),
        // 'MNT': require('@/assets/images/flags/mongolia.svg'),
        'ZAR': require('@/assets/images/flags/south_africa.png'),
        'NGN': require('@/assets/images/flags/nigeria.png'),
        'PLN': require('@/assets/images/flags/poland.png'),
        'NZD': require('@/assets/images/flags/newzealand.png'),
        'CAD': require('@/assets/images/flags/canada.svg'),
    }
    return flags;
}

export default Common;