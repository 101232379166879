import { PaymentMethodsService } from '../../helpers/backend/cross_border_methods.service';
import {
  FETCH_CROSS_BORDER_PAYMENT_METHODS,
  CROSS_BORDER_UPDATE_ENABLE_AND_DEFAULT_STATUS
} from '../actions.type';
import {
    ADD_CROSS_BORDER_PAYMENT_METHODS_IN_STORE
} from '../mutations.type';

export const state = {
  methods:  {},
  countryList:[]
}

export const mutations = {
  [ADD_CROSS_BORDER_PAYMENT_METHODS_IN_STORE](state, response) {
    state.countryList = Object.keys(response)
    state.methods = response
  }
}

export const getters = {}

export const actions = {

  [FETCH_CROSS_BORDER_PAYMENT_METHODS](context) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const iamportKey = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      PaymentMethodsService.fetchCrossBorderPaymentMethods(merchantUUID, iamportKey, environment).then((response) => {
        context.commit(ADD_CROSS_BORDER_PAYMENT_METHODS_IN_STORE, response.content)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
  [CROSS_BORDER_UPDATE_ENABLE_AND_DEFAULT_STATUS](context, { statusData, countryForMethod }) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      statusData['environment'] = environment;
      PaymentMethodsService.crossBorderupdateEnableAndDefaultStatusForMethod(merchantUUID, statusData, countryForMethod).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
}

export const namespaced = true;
