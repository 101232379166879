import Headers from '../headers';
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const FeeManagementService = {
  fetchChannelListForFeeManagement,
  fetchFeeConfigMeta,
  fetchFeeConfigRecords,
  updateFeeConfigMeta,
  updateFeeConfigRecord,
  updateFoodTechFeeConfigRecord,
  fetchPaymentMethodsOfPaymentChannel,
  createFeeConfigRecord,
  createFeeConfigRecordFoodTech,
  deleteFeeConfigRecord,
  updateToleranceLevel,
  fetchToleranceLevel
};

function fetchChannelListForFeeManagement(merchantUUId, iamportKey, environment) {
    const requestOptions = {
      method: 'GET',
      headers: { ...Headers.apiHeaders(),'X-Chaipay-Client-Key': iamportKey },
      credentials: 'include',
    };
    const queryParams = new URLSearchParams({environment});
    return fetch(`${CHAIPAY_API_URL}/merchants/recon/channels?` + queryParams, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function fetchFeeConfigMeta(merchantUUId, iamportKey, pcKey, environment) {
    const requestOptions = {
      method: 'GET',
      headers: Headers.apiHeaders(),
      credentials: 'include',
    };
    const queryParams = new URLSearchParams({environment});
    return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paychannel/${pcKey}/fee-config-metadata?` + queryParams, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function fetchFeeConfigRecords(iamportKey, params) {
    const requestOptions = {
      method: 'GET',
      headers: {...Headers.apiHeaders(),'X-Chaipay-Client-Key': iamportKey},
      credentials: 'include',
    };
    console.log(params);
    
    const queryParams = new URLSearchParams(params);
    return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/charge-config/all?` + queryParams, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function updateFeeConfigMeta(merchantUUId, iamportKey, pcKey, environment, data) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    };
    const queryParams = new URLSearchParams({environment});
    return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paychannel/${pcKey}/fee-config-metadata?` + queryParams, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function fetchToleranceLevel(iamportKey, pcKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: {...Headers.apiHeaders(),'X-Chaipay-Client-Key': iamportKey},
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({payment_channel_key: pcKey, environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/tolerance/get?${queryParams}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateToleranceLevel(iamportKey, data) {
  const requestOptions = {
    method: 'POST',
    headers: {...Headers.apiHeaders(),'X-Chaipay-Client-Key': iamportKey},
    body: JSON.stringify(data),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/tolerance/update`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateFeeConfigRecord(iamportKey, data) {
    const requestOptions = {
      method: 'POST',
      headers: {...Headers.apiHeaders(),'X-Chaipay-Client-Key': iamportKey},
      body: JSON.stringify(data),
      credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/charge-config/update`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function updateFoodTechFeeConfigRecord(iamportKey, pcKey, environment, data) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(data),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paychannel/${pcKey}/update-foodtech-fee-config-record?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchPaymentMethodsOfPaymentChannel(merchantUUId, iamportKey, pcKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paychannel/${pcKey}/currency-mapping?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function createFeeConfigRecord(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'x-chaipay-client-key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/charge-config/create`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function createFeeConfigRecordFoodTech(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'x-chaipay-client-key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/charge-config/create`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function deleteFeeConfigRecord(merchantUUId, iamportKey, pcKey, environment, data) {
  const requestOptions = {
    method: 'DELETE',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(data),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paychannel/${pcKey}/delete-fee-config-record?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}