import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const SubscriptionPriceService = {
    addSubscriptionPrice,
    fetchSubscriptionPriceList,
    deletePrice
};

function addSubscriptionPrice(entityDetails) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify(entityDetails),
      credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchant/subscription/createPrice`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function fetchSubscriptionPriceList(merchantUuid, payload, isMasterMerchant, iamportKey) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify(payload),
      credentials: 'include',
    };

    let URI = `merchant/subscription/price/${merchantUuid}/list`;
    if(isMasterMerchant) {
      URI = `master-merchant/${iamportKey}/merchants/price-list`;
    }

    return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function deletePrice(orderRef, iamportKey) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/subscription/price/${orderRef}/status/cancelled`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}