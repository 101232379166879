import Headers from '../headers'

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const InvoiceService = {
  getInvoiceList,
  exportInvoices,
  getInvoiceTemplatesList,
  getInvoiceDetailsList,
  invoiceReminder,
  invoiceVoid,
  invoiceUnvoid,
  markScheduleSent,
  createInvoice,
  updateInvoice,
  getInvoiceTemplateDetails,
  getInvoiceTimeline,
  invoiceDeleteTemplate,
  createInvoiceTemplate,
  updateInvoiceTemplate,
  bulkInvoicesImport
};

function getInvoiceList(merchantUUID,iamportKey, data, isMasterMerchant) {
  const URI = isMasterMerchant ? `master-merchant/${iamportKey}/invoices` : `merchants/${merchantUUID}/invoices`;
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(data.payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getInvoiceDetailsList(iamportKey, isMasterMerchant, environment, ref) {
  const URI = isMasterMerchant ? `merchants/${iamportKey}/invoice-details` : `merchants/${iamportKey}/invoice-details`;
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({"environment":environment,'invoice_ref':ref }),
    credentials: 'include',
  };
  
  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function markScheduleSent(isMasterMerchant, payload) {
  const URI = isMasterMerchant ? `merchants/invoice/${payload.invoice_ref}/send-invoice` : `merchants/invoice/${payload.invoice_ref}/send-invoice`;
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  
  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getInvoiceTimeline(iamportKey, isMasterMerchant, invoice_ref, environment) {
  const URI = isMasterMerchant ? `merchants/${iamportKey}/events-timeline` : `merchants/${iamportKey}/events-timeline`;
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({"environment":environment,'invoice_ref':invoice_ref }),
    credentials: 'include',
  };
  
  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function invoiceReminder(isMasterMerchant, environment, ref) {
  const URI = isMasterMerchant ? `merchants/invoice/${ref}/send-reminder?` : `merchants/invoice/${ref}/send-reminder?`;
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };

  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/${URI}`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function invoiceVoid(isMasterMerchant, environment, ref) {
  const URI = isMasterMerchant ? `merchants/invoice/${ref}/void?` : `merchants/invoice/${ref}/void?`;
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };

  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/${URI}`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function invoiceUnvoid(isMasterMerchant, environment, ref) {
  const URI = isMasterMerchant ? `merchants/invoice/${ref}/unvoid?` : `merchants/invoice/${ref}/unvoid?`;
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };

  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/${URI}`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getInvoiceTemplatesList(iamportKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };

  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/invoice_templates?`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getInvoiceTemplateDetails(iamportKey, templateRef) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/invoice_templates/${templateRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function exportInvoices(iamportKey, data, isMasterMerchant) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(data.payload),
  };
  const URI = isMasterMerchant ? `master-merchant/${iamportKey}/export-invoices` : `merchants/${iamportKey}/export-invoices`;
  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "invoices_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function createInvoice(payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/invoice`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateInvoice(payload, invoiceRef) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${payload.portone_key}/invoice/${invoiceRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response;
      // return response.json();
    } else {
      // if(response.status === 401) {
      //   window.location = window.location.origin + '/logout';
      // }
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

function createInvoiceTemplate(payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/invoice_template`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateInvoiceTemplate(payload) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${payload.portone_key}/invoice_templates/${payload.templateRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function invoiceDeleteTemplate(iamportKey, environment, ref) {
  const URI = `merchants/${iamportKey}/invoice_templates/${ref}?`;
  const requestOptions = {
    method: 'DELETE',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };

  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/${URI}`+ queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function bulkInvoicesImport(iamportKey, formData) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(false),
    credentials: 'include',
    body: formData
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/bulk-create-invoices`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}