export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import moment from "moment";
import Headers from '../headers';

export const ReserveLedgerService = {
  fetchLedger,
  downloadExcelLedger
};

function fetchLedger(merchantUUID, {iamportKey,environment,data}) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({"key":iamportKey,"page":data.page,"page_size":data.pagesize,"from":moment(data.from).format('YYYY-MM-DD HH:mm:ss'),"to":moment(data.to).format('YYYY-MM-DD HH:mm:ss'),"filters":{},"environment":environment}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/cross-border/reserve/statement`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function downloadExcelLedger(merchantUUID, {iamportKey,environment,data}) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({"key":iamportKey,"page":data.page,"page_size":data.pagesize,"from":moment(data.from).format('YYYY-MM-DD HH:mm:ss'),"to":moment(data.to).format('YYYY-MM-DD HH:mm:ss'),"filters":{},"environment":environment}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/cross-border/reserve/download-statement?`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "reserve-ledger_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}