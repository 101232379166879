import {
  VirtualAccountService
} from '../../helpers/backend/virtual_account.service';
import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_VIRTUAL_ACCOUNTS,
  CREATE_VIRTUAL_ACCOUNT,
  FETCH_VIRTUAL_ACCOUNTS_MASTER_MERCHANT
} from '../actions.type';
import {
  UPDATE_VIRTUAL_ACCOUNT_IN_STORE,
  CREATE_VIRTUAL_ACCOUNT_IN_STORE,
  TOGGLE_VIRTUAL_LOADER_IN_STORE
} from '../mutations.type';

export const state = {
  isFetchingData: false,

  virtualAccounts: [],
}

export const mutations = {
  [TOGGLE_VIRTUAL_LOADER_IN_STORE](state,toggle){
    state.isFetchingData = toggle;
  },
  [UPDATE_VIRTUAL_ACCOUNT_IN_STORE](state, virtualAccounts) {
    state.virtualAccounts = cloneDeep(virtualAccounts)
  },
  [CREATE_VIRTUAL_ACCOUNT_IN_STORE](state, virtualAccounts) {
    state.virtualAccounts.push(virtualAccounts);
  },

}

export const getters = {
  getVirtualAccounts(state) {
    return state.virtualAccounts;
  }
}

export const actions = {
  [FETCH_VIRTUAL_ACCOUNTS](context) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      context.commit(TOGGLE_VIRTUAL_LOADER_IN_STORE, true);
      VirtualAccountService.fetchVirtualAccounts(key, environment).then((response) => {
      context.commit(TOGGLE_VIRTUAL_LOADER_IN_STORE, false);

        context.commit(UPDATE_VIRTUAL_ACCOUNT_IN_STORE, response.content);
        resolve(response);
      }).catch((error) => {
      context.commit(TOGGLE_VIRTUAL_LOADER_IN_STORE, false);
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, {
          root: true
        })
        reject(error);
      })
    })
  },

  [FETCH_VIRTUAL_ACCOUNTS_MASTER_MERCHANT](context, payload) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      payload.environment = context.rootState.auth.environment;
      context.commit(TOGGLE_VIRTUAL_LOADER_IN_STORE, true);
      VirtualAccountService.fetchVirtualAccountsMasterMerchant(key, payload).then((response) => {
      context.commit(TOGGLE_VIRTUAL_LOADER_IN_STORE, false);

        context.commit(UPDATE_VIRTUAL_ACCOUNT_IN_STORE, response.content.content);
        resolve(response);
      }).catch((error) => {
      context.commit(TOGGLE_VIRTUAL_LOADER_IN_STORE, false);
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, {
          root: true
        })
        reject(error);
      })
    })
  },

  [CREATE_VIRTUAL_ACCOUNT](context, virtualAccount) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      virtualAccount['environment'] = environment;
      VirtualAccountService.createVirtualAccount(merchantUUID, key, virtualAccount).then((response) => {
        context.dispatch(`${FETCH_VIRTUAL_ACCOUNTS}`);
        context.commit('notification/success', "Virtual Account Created Successfully", {
          root: true
        })
        resolve(response);

      }).catch((error) => {
       // const errorObject = JSON.parse(error.message)
          context.commit('notification/error', "Something went wrong", {
          root: true
        })
        reject(error);
      })
    })
  }
}
export const namespaced = true;