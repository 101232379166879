import { PaymentGatewayService } from '../../helpers/backend/payment_gateway.service';
import {
  FETCH_WEBHOOKS,
  ADD_WEBHOOK,
  UPDATE_WEBHOOK,
  DELETE_WEBHOOK,
  UPDATE_WEBHOOK_BY_FORM,
  UPDATE_SECRET_KEY_BY_FORM,
} from '../actions.type';
import {
  ADD_WEBHOOKS_IN_STORE,
  ADD_WEBHOOK_IN_STORE,
  UPDATE_WEBHOOK_IN_STORE,
  UPDATE_WEBHOOK_BY_FORM_IN_STORE,
  UPDATE_SECRET_KEY_BY_FORM_IN_STORE,
} from '../mutations.type';

export const state = {
  urls: [],
}

export const mutations = {
  [ADD_WEBHOOKS_IN_STORE](state, webhookDetails) {
    state.urls = webhookDetails;
  },
  [ADD_WEBHOOK_IN_STORE](state, webhookDetail) {
    state.urls.push(webhookDetail);
  },
  [UPDATE_WEBHOOK_IN_STORE](state, webhookDetail) {
    state.urls = state.urls.map(data => {
      if (data.uuid === webhookDetail.uuid) {
        data.enabled = webhookDetail.enabled;
      }
      return data;
    });
  },
  [UPDATE_WEBHOOK_BY_FORM_IN_STORE](state, webhookDetail) {
    state.urls = state.urls.map(data => {
      if (data.uuid === webhookDetail.uuid) {
        data.name = webhookDetail.name;
        data.url = webhookDetail.url;
        data.type = webhookDetail.type;
        //data.enabled = webhookDetail.enabled;
      }
      return data;
    });
  },

  [UPDATE_SECRET_KEY_BY_FORM_IN_STORE](state, secretKeyDetail) {
    state.urls = state.urls.map(data => {
      if (data.iamportkey === secretKeyDetail.iamportkey) {
        data.secureSecret = secretKeyDetail.secureSecret;
        //data.enabled = webhookDetail.enabled;
      }
      return data;
    });
  },
}

export const getters = {
  getRegisteredWebhookURLs(state) {
    return state.urls;
  },
}

export const actions = {

  [FETCH_WEBHOOKS](context) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      PaymentGatewayService.getWebhooksData(merchantUUID, environment).then((response) => {
        const webhookDetails = response;
        context.commit(ADD_WEBHOOKS_IN_STORE, webhookDetails)
        resolve(webhookDetails);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [UPDATE_SECRET_KEY_BY_FORM](context, { secretKeyData }) {
    return new Promise((resolve, reject) => {
      let key = context.rootState.auth.currentUser.iamportKey;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      secretKeyData['environment'] = environment
      PaymentGatewayService.updateSecureSecretKeyData(merchantUUID, key, secretKeyData).then((response) => {
        const secretKeyDetail = response;
        context.commit(UPDATE_SECRET_KEY_BY_FORM_IN_STORE, secretKeyDetail)
        resolve(secretKeyDetail);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },



  [ADD_WEBHOOK](context, webhookData) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      webhookData['environment'] = environment
      PaymentGatewayService.addWebhookData(merchantUUID, webhookData).then((response) => {
        const webhookDetail = response;
        context.commit(ADD_WEBHOOK_IN_STORE, webhookDetail)
        resolve(webhookDetail);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
  [DELETE_WEBHOOK](context, linkRef) {
    let key = context.rootState.auth.currentUser.iamportKey;
    const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    return new Promise((resolve, reject) => {
      PaymentGatewayService.deleteWebhookData(merchantUUID, linkRef, key).then((response) => {
        console.log("response");
        console.log(response);
        context.commit('notification/success', "Webhook Deleted successfully", { root: true })
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [UPDATE_WEBHOOK](context, webhookData) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      webhookData['environment'] = environment
      PaymentGatewayService.updateWebhookData(merchantUUID, webhookData).then((response) => {
        const webhookDetail = response;
        context.commit(UPDATE_WEBHOOK_IN_STORE, webhookDetail)
        resolve(webhookDetail);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
  [UPDATE_WEBHOOK_BY_FORM](context, webhookData) {
    return new Promise((resolve, reject) => {
      let key = context.rootState.auth.currentUser.iamportKey;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      //const environment = context.rootState.auth.environment;
      //webhookData['environment'] = environment
      PaymentGatewayService.updateWebhookByFormData(merchantUUID, key, webhookData).then((response) => {
        const webhookDetail = response;
        context.commit(UPDATE_WEBHOOK_BY_FORM_IN_STORE, webhookDetail)
        resolve(webhookDetail);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
}

export const namespaced = true;
