import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const PaymentPageService = {
  fetchPaymentPageResults,
  createPaymentPage,
  updatePaymentPage,
  fetchPaymentPageDetails,
  fetchPaymentPageTransactions,
  uploadPaymentPageImage,
  exportPaymentPageTransactions,
  cancelPaymentPage,
  exportPaymentPageList,
  checkCustomUrlAvailablity,
  generateQRCode
};

function fetchPaymentPageResults(iamportKey, pageData, isMasterMerchant) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(pageData),
    credentials: 'include',
  };
  const url = isMasterMerchant ? `master-merchant/${iamportKey}/merchants/payment-pages` : `merchants/${iamportKey}/paymentPages`;

  return fetch(`${CHAIPAY_API_URL}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function createPaymentPage(merchantUUID, iamportKey, data) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(data),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updatePaymentPage(merchantUUID, iamportKey, data, pageRef) {
  const requestOptions = { 
    method: 'PUT',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(data),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/${pageRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchPaymentPageDetails(merchantUUID, iamportKey, pageRef) {
  const requestOptions = { 
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paymentPage/${pageRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchPaymentPageTransactions(iamportKey, payload, pageRef) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(payload),
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/${pageRef}/transactions`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function uploadPaymentPageImage(merchantUUID, iamportKey, formData) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(false), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: formData,
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/logo`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function exportPaymentPageTransactions(iamportKey, pageData, pageRef) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(pageData),
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/${pageRef}/export-transactions`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "payment-page-transactions_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function cancelPaymentPage(merchantUUID, iamportKey, pageRef) {
  const requestOptions = { 
    method: 'PUT',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/${pageRef}/status/cancelled`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function exportPaymentPageList(iamportKey, data, isMasterMerchant) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(data),
  };

  const url = isMasterMerchant ? `master-merchant/${iamportKey}/merchants/export-payment-pages` : `merchants/${iamportKey}/export-paymentPages`;
  
  return fetch(`${CHAIPAY_API_URL}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "payment-pages_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function checkCustomUrlAvailablity(merchantUUID, iamportKey, pageRef) {
  const requestOptions = { 
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include'
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/check/${pageRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function generateQRCode(iamportKey, pageRef) {
  const requestOptions = { 
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include'
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentPage/${pageRef}/generate-qr-code`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}