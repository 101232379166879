import store from '@/state/store'
import { LOGOUT } from "@/state/actions.type";

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'Dashboard' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            title: "Register",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'Dashboard' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            title: "Forgot Password",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'Dashboard' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password',
        name: 'Reset-password',
        component: () => import('../views/pages/account/reset-password'),
        meta: {
            title: "Reset Password",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'Dashboard' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            title: "Logout",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const reconDates = localStorage.getItem('recon_dates')
                const reconDashbaordDates = localStorage.getItem('recon_dashboard_dates')
                store.dispatch(`auth/${LOGOUT}`)
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'Dashboard' } : { ...routeFrom })
                if(reconDates) {
                    localStorage.setItem('recon_dates', reconDates)
                    localStorage.setItem('recon_dashboard_dates', reconDashbaordDates)
                }
            },
        },
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            title: "Dashboard",
            description: "Welcome to your PortOne Dashboard",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/dashboard/index')
    },
    { path: '/', redirect: { name: 'Dashboard' } },
    // --------------------------------------------------
    // Transactions
    {
        path: '/transactions/payments',
        name: 'Transactions',
        meta: {
            title: "Transactions",
            description: "Here are all your transactions of your account",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-user') ||
                    userRolesList.includes('payment-refund-user') ||
                    userRolesList.includes('payment-links-user') ||
                    userRolesList.includes('payment-pages-user') ||
                    userRolesList.includes('recon-user') ||
                    userRolesList.includes('recon-admin') ||
                    userRolesList.includes('invoice-user')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/transactions/payments')
    },
    {
        path: '/transactions/payments/payment-details',
        name: 'Transaction Details',
        meta: {
            title: "Transactions",
            description: "Here are all your transactions of your account",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-user') ||
                    userRolesList.includes('payment-refund-user') ||
                    userRolesList.includes('payment-links-user') ||
                    userRolesList.includes('payment-pages-user') ||
                    userRolesList.includes('invoice-user')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/transactions/payment-details')
    },
    {
        path: '/transactions/payments/payment-details/refund',
        name: 'Transaction Refund',
        meta: {
            title: "Transaction Refund",
            description: "Here are all your transactions of your account",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-refund-user')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/transactions/refund')
    },
    {
        path: '/general-settings',
        name: 'General Settings',
        meta: {
            title: "General Settings",
            description: "Personalize your PortOne account preferences",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (!userRolesList.includes('admin') && !userRolesList.includes('sub-admin')) {
                    // Redirect to the home page instead
                    next({ name: 'User Profile' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
        component: () => import('../views/pages/integration/settings')
    },

    // --------------------------------------------------
    // Integration - API Settings
    // {
    //     path: '/integration/api-general',
    //     name: 'General Settings',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             if (!userRolesList.includes('admin') && !userRolesList.includes('sub-admin')) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'User Profile' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/integration/api-general')
    // },

    // {
    //     path: '/integration/api-keys',
    //     name: 'API Keys',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/integration/api-keys')
    // },

    // {
    //     path: '/integration/api-webhooks',
    //     name: 'Webhooks',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             if (
    //                 userRolesList.includes('admin') ||
    //                 userRolesList.includes('sub-admin') ||
    //                 userRolesList.includes('payment-admin') ||
    //                 userRolesList.includes('payouts-admin')
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     // component: () => import('../views/pages/forms/validation')
    //     component: () => import('../views/pages/integration/api-webhooks')
    // },

    // {
    //     path: '/integration/api-security',
    //     name: 'Security Settings',
    //     meta: { authRequired: true },
    //     // component: () => import('../views/pages/ui/tabs-accordions')
    //     component: () => import('../views/pages/integration/api-security')
    // },
    // --------------------------------------------------
    // Reconciliation 
    {
        path: '/reconciliation/data',
        name: 'Upload Files',
        meta: {
            title: "Data Ingestion",
            description: "Automated payment reconciliations for ultimate efficiency",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-user') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/reconciliation/recon')
    },
    {
        path: '/reconciliation/data/list',
        name: 'Reconciliation',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-user') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/reconciliation/reconciliations')
    },
    {
        path: '/reconciliation/data/details',
        name: 'Reconciliation Details',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const merchantList = store.getters['auth/getMerchantList'];
                const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-user') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/reconciliation/recon-details')
    },
    {
        path: '/reconciliation/dashboard',
        name: 'Reconciliation Dashboard',
        meta: {
            title: "Reconciliation Dashboard",
            description: "Automated payment reconciliations for ultimate efficiency",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const merchantList = store.getters['auth/getMerchantList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-user') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/reconciliation/dashboard')
    },
    {
        path: '/reconciliation/configuration',
        name: 'Configuration',
        meta: {
            title: "Configuration",
            description: "Automated payment reconciliations for ultimate efficiency",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const merchantList = store.getters['auth/getMerchantList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-configurator') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/reconciliation/configuration')
    },
    {
        path: '/reconciliation/analytics',
        name: 'Analytics',
        meta: {
            title: "Analytics",
            description: "",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const merchantList = store.getters['auth/getMerchantList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-configurator') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant')) && store.state.auth.currentUser.is_onboarding_completed && store.state.auth.currentUser.recon_analytics_enabled
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/reconciliation/analytics')
    },
    // {
    //     path: '/reconciliation/summary',
    //     name: 'Reconciliation Summary',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             const merchantList = store.getters['auth/getMerchantList'];
    //             const crossBorderList = store.getters['auth/getCrossBorder'];
    //             if (
    //                 (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/reconciliation/recon_summary')
    // },
    // {
    //     path: '/reconciliation/file-management',
    //     name: 'File Management',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             const merchantList = store.getters['auth/getMerchantList'];
    //             const crossBorderList = store.getters['auth/getCrossBorder'];
    //             if (
    //                 (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/reconciliation/file-management')
    // },
    // {
    //     path: '/reconciliation/psp-summary',
    //     name: 'Reconciliation Summary For PSP',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             const merchantList = store.getters['auth/getMerchantList'];
    //             const crossBorderList = store.getters['auth/getCrossBorder'];
    //             if (
    //                 (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/reconciliation/recon_summary_for_psp')
    // },
    // {
    //     path: '/reconciliation/psp-month-view',
    //     name: 'Reconciliation PSP Summary',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             const merchantList = store.getters['auth/getMerchantList'];
    //             const crossBorderList = store.getters['auth/getCrossBorder']
    //             if (
    //                 (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/reconciliation/recon_psp_summary')
    // },

    // {
    //     path: '/reconciliation/fee-management',
    //     name: 'Fee Management',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             const merchantList = store.getters['auth/getMerchantList'];
    //             const crossBorderList = store.getters['auth/getCrossBorder']
    //             if (
    //                 (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/reconciliation/fee-management')
    // },
    // {
    //     path: '/reconciliation/transaction',
    //     name: 'Order Ingestion',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             const merchantList = store.getters['auth/getMerchantList'];
    //             const crossBorderList = store.getters['auth/getCrossBorder']
    //             if (
    //                 (userRolesList.includes('admin') || userRolesList.includes('sub-admin') || userRolesList.includes('recon-user') || userRolesList.includes('recon-admin')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/reconciliation/recon_payments')
    // },

    // --------------------------------------------------
    // Master Merchant 
    {
        path: '/master-merchant',
        name: 'Manage Merchant',
        meta: {
            title: "Manage Merchant",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && merchantList.includes('master-merchant') && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/master-merchant/master-merchant-list')
    },
    {
        path: '/settings/payment-pages',
        name: 'Payment Pages',
        meta: {
            title: "Payment Pages",
            description: "Personalized branded pages for easy selling and payment collection!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-pages-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payment-pages/payment-page-table')
    },
    {
        path: '/settings/payment-pages-details',
        name: 'Payment Page Details',
        meta: {
            title: "Payment Pages",
            description: "Personalized branded pages for easy selling and payment collection!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-pages-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payment-pages/payment-page-details')
    },
    {
        path: '/settings/payment-pages/config',
        name: 'Payment Page',
        meta: {
            title: "Payment Pages",
            description: "Personalized branded pages for easy selling and payment collection!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-pages-user')) && !merchantList.includes('master-merchant') && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payment-pages/payment-page-config')
    },
    // --------------------------------------------------
    // Integration - Payment Settings
    // {
    //     path: '/integration/pay-channels',
    //     name: 'Payment Channels Old',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             if (
    //                 userRolesList.includes('admin') ||
    //                 userRolesList.includes('sub-admin') ||
    //                 userRolesList.includes('payment-channel-configurator')
    //             ) {
    //                 // Continue to the current page
    //                 next()
    //             } else {
    //                 // Redirect to the User profile page instead
    //                 next({ name: 'User Profile' })
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/integration/pay-channels')
    // },
    {
        path: '/settings/pay-channel',
        name: 'Payment Channels',
        meta: {
            title: "Payment Channels",
            description: "Link, activate, and customize your payment channels and experience",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder']
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('recon-admin') ||
                    userRolesList.includes('recon-configurator') ||
                    userRolesList.includes('payment-channel-configurator')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/settings/payin-channel/payment-channel-mains')
    },
    {
        path: '/settings/pay-channel/update-config',
        name: 'Payment Channel Configuration',
        meta: {
            title: "Payment Channels",
            description: "Link, activate, and customize your payment channels and experience",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder']
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('recon-admin') ||
                    userRolesList.includes('recon-configurator') ||
                    userRolesList.includes('payment-channel-configurator')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/settings/payin-channel/update-config')
    },
    {
        path: '/settings/pay-channel/update-config-onboarding',
        name: 'Payment Channel Onboarding',
        meta: {
            title: "Payment Channels",
            description: "Link, activate, and customize your payment channels and experience",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder']
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('recon-admin') ||
                    userRolesList.includes('recon-configurator') ||
                    userRolesList.includes('payment-channel-configurator')) && !(crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/settings/payin-channel/update-config-onboarding')
    },
    {
        path: '/settings/payout-channel',
        name: 'Payout Channels',
        meta: {
            title: "Payout Channels",
            description: "Link, activate, and customize your payout channels and experience",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payout-channel-configurator')) && store.state.auth.currentUser.is_onboarding_completed
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/settings/payout-channel/pay-channels')
    },
    {
        path: '/settings/payout-channel/update-config',
        name: 'Payout Channel Configuration',
        meta: {
            title: "Payout Channels",
            description: "Link, activate, and customize your payout channels and experience",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payout-channel-configurator')) && store.state.auth.currentUser.is_onboarding_completed
                    // && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/settings/payout-channel/update-config')
    },
    {
        path: '/payment-links',
        name: 'Payment Links',
        meta: {
            title: "Payment Links",
            description: "Create and share payment links with ease, without writing any code!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-pages-user') ||
                    userRolesList.includes('payment-links-user') ||
                    userRolesList.includes('invoice-user')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payment-links/payment-links')
    },
    {
        path: '/invoices',
        name: 'Invoices',
        meta: {
            title: "Invoices",
            description: "Create and share Invoices with ease, without writing any code!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('invoice-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/invoice/invoices')
    },
    {
        path: '/invoices/invoice-form',
        name: 'Invoices Create',
        meta: {
            title: "Invoices",
            description: "Create and share Invoices with ease, without writing any code!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('invoice-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/invoice/invoice-form')
    },
    {
        path: '/invoices/invoice-form-update',
        name: 'Invoices Update',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('invoice-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/invoice/invoice-form')
    },
    {
        path: '/invoices/invoices-details',
        name: 'Invoices Details',
        meta: {
            title: "Invoices",
            description: "Create and share Invoices with ease, without writing any code!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('invoice-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/invoice/invoices_details')
    },
    {
        path: '/invoices/create-invoice-template',
        name: 'Create Invoice Template',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('invoice-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/invoice/invoice-template-form')
    },
    {
        path: '/payment-links/create-payment-links',
        name: 'Payment Link',
        meta: {
            title: "Payment Links",
            description: "Create and share payment links with ease, without writing any code!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-pages-user') ||
                    userRolesList.includes('payment-links-user') ||
                    userRolesList.includes('invoice-user')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payment-links/payment-link-form')
    },
    {
        path: '/payment-links-details',
        name: 'Payment Link Details',
        meta: {
            title: "Payment Links",
            description: "Create and share payment links with ease, without writing any code!",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin') ||
                    userRolesList.includes('payment-pages-user') ||
                    userRolesList.includes('payment-links-user') ||
                    userRolesList.includes('invoice-user')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payment-links/payment-link-details')
    },
    {
        path: '/payouts/virtual-account',
        name: 'Virtual Account',
        meta: {
            title: "Virtual Account",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user')) && store.state.auth.currentUser.is_onboarding_completed
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/virtual-account')
    },
    {
        path: '/payouts/virtual-account/transactions',
        name: 'Payout Transactions',
        meta: {
            title: "Virtual Account",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/transactions')
    },
    {
        path: '/payouts/vendor-accounts',
        name: 'Vendor Accounts',
        meta: {
            title: "Vendor Accounts",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/vendor-accounts')
    },
    {
        path: '/payouts/vendors',
        name: 'Vendors',
        meta: {
            title: "Vendors",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user')) && store.state.auth.currentUser.is_onboarding_completed
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/vendors')
    },
    {
        path: '/payouts/vendors/create-beneficiary',
        name: 'Create Beneficiary',
        meta: {
            title: "Create Beneficiary",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user'))
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/beneficiary-form')
    },

    {
        path: '/payouts/vendors/update-beneficiary',
        name: 'Update Beneficiary',
        meta: {
            title: "Update Beneficiary",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user'))
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/beneficiary-form')
    },

    // REMITTANCES
    {
        path: '/remittances/dashboard',
        name: 'Remittance Home',
        meta: {
            title: "Remittance",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && crossBorderList.includes('cross-border-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/remittances/dashboard/index')
    },
    {
        path: '/remittances/settlements',
        name: 'Remittance Settlements',
        meta: {
            title: "Remittance",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && crossBorderList.includes('cross-border-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/remittances/settlements')
    },
    {
        path: '/remittances/payment-methods',
        name: 'Remittance Methods',
        meta: {
            title: "Remittance",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && (crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/remittances/payment_methods')
    },
    {
        path: '/remittances/summary',
        name: 'Remittance Summary',
        meta: {
            title: "Remittance",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && crossBorderList.includes('cross-border-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/remittances/remittance_summary')
    },
    {
        path: '/remittances/reserve-ledger',
        name: 'Reserve Ledger',
        meta: {
            title: "Reserve Ledger",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && crossBorderList.includes('cross-border-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/remittances/reserve_ledger')
    },
    {
        path: '/settings/checkout-preview',
        name: 'Checkout Preview',
        meta: {
            title: "Checkout Preview",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder']
                const merchantList = store.getters['auth/getMerchantList'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && (crossBorderList.includes('cross-border-merchant') && !merchantList.includes('master-merchant'))
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/settings/payin-channel/pay-channels')
    },
    // --------------------------------------------------
    {
        path: '/remittances/bank-account',
        name: 'BankAccount',
        meta: {
            title: "BankAccount",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                const crossBorderList = store.getters['auth/getCrossBorder'];
                if (
                    (userRolesList.includes('admin') || userRolesList.includes('sub-admin')) && crossBorderList.includes('cross-border-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/remittances/bank-account')
    },

    {
        path: '/notifications',
        name: 'notifications',
        meta: { authRequired: true },
        component: () => import('../views/pages/notifications/index')
    },

    {
        path: '/profile',
        name: 'User Profile',
        meta: { authRequired: true, title: "User Profile" },
        component: () => import('../views/pages/account/profile')
    },
    // {
    //     path: '/accounts',
    //     name: 'Accounts',
    //     meta: {
    //         title: "Accounts",
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             const userRolesList = store.getters['auth/getUserRolesList'];
    //             if (!userRolesList.includes('admin') && !userRolesList.includes('sub-admin')) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'User Profile' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    //     component: () => import('../views/pages/account/accounts')
    // },
    {
        path: '/payouts/manage-payouts',
        name: 'Payouts',
        meta: {
            title: "Payouts",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user')) && store.state.auth.currentUser.is_onboarding_completed
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/payouts')
    },
    {
        path: '/payouts/manage-payouts/create-payout',
        name: 'Create Payouts',
        meta: {
            title: "Create Payouts",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user'))
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/payout-form')
    },
    {
        path: '/payouts/manage-payouts/update-payout',
        name: 'Update Payout',
        meta: {
            title: "Update Payout",
            description: "Manage all your payouts here",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                // const merchantList = store.getters['auth/getMerchantList'];
                // const crossBorderList = store.getters['auth/getCrossBorder']
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payouts-admin') ||
                    userRolesList.includes('payouts-user') ||
                    userRolesList.includes('payouts-verify-user') ||
                    userRolesList.includes('payouts-approve-user'))
                    //  && merchantList.includes('single-merchant') && crossBorderList.includes('single-merchant')
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/payouts/payout-form')
    }, 
    {
        path: '/payouts/payout-settings',
        name: 'Payout Settings',
        meta: { 
            authRequired: true,
            title: "Payout Settings" 
        },
        component: () => import('../views/pages/payouts/payout-settings')
    },
    {
        path: '/subscription-price',
        name: 'Subscription Plan',
        meta: {
            title: "Subscriptions",
            description: "Create and manage subscriptions for your customers",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/subscription/price')
    },
    {
        path: '/subscriptions/create-subscription-price',
        name: 'Create Subscription Plan',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/subscription/price-form')
    },
    {
        path: '/subscriptions',
        name: 'Subscription',
        meta: {
            title: "Subscriptions",
            description: "Create and manage subscriptions for your customers",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/subscription/subscription_main')
    },
    {
        path: '/subscriptions/create-subscriptions',
        name: 'Create Subscription',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/subscription/subscription-form')
    },
    {
        path: '/subscriptions/details',
        name: 'Subscription Details',
        meta: {
            title: "Subscriptions",
            description: "Create and manage subscriptions for your customers",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                const userRolesList = store.getters['auth/getUserRolesList'];
                if (
                    (userRolesList.includes('admin') ||
                    userRolesList.includes('sub-admin') ||
                    userRolesList.includes('payment-admin')) && store.state.auth.currentUser.is_onboarding_completed
                ) {
                    // Continue to the current page
                    next()
                } else {
                    // Redirect to the User profile page instead
                    next({ name: 'User Profile' })
                }
            },
        },
        component: () => import('../views/pages/subscription/subscription-details')
    },

	// --------------------------------------------------
    // Email Verification
    {
        path: '/pages/verify-email',
        name: 'Verify Email',
        meta: { authRequired: true, title: "Verify Email" },
        component: () => import('../views/pages/support/verify-email')
    },

    // Add redirect routes to
    {
        path: '/pageNotFound',
        name: 'pageNotFound',
        meta: { authRequired: false, title: "Page Not Found" },
        component: () => import('../views/pages/page-not-found')
    },
    {
        path: '*',
        redirect: '/'
    },
]
