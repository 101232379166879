import store from '@/state/store'

const Headers = {
    apiHeaders
}

function apiHeaders(contentType = true) {
    const token = localStorage.getItem("token");
    let obj = {
        'Authorization': `Bearer ${token}`,
        'X-Chaipay-Client-UUID': store.getters['auth/subMerchantUUID']
    }
    if(contentType) {
        obj = { ...obj, 'Content-Type': 'application/json' }
    }
    return obj;
}

export default Headers;