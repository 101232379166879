import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const PaymentLinksService = {
  fetchPaymentLinks,
  addPaymentLink,
  deletePaymentLink,
  fetchPaymentLinkDetails,
  fetchPaymentLinkTransactions,
  checkRefPaymentLink,
  notificationPaymentLink,
  exportPaymentLinkTransactions,
  generateQRCode,
  getSourceList
};

function fetchPaymentLinks(merchantUUID, iamportKey, pageIndex, pagesize, filters, isMasterMerchant, merchant_uuids, environment) {
  const toDate = !filters.to || filters.to === "" ? new Date() : filters.to;
  const fromDate = !filters.from || filters.from === "" ? new Date('2006-01-02 15:04:05') : filters.from;
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.key;
      });
    }else{
      uuidsToSend = [];
    }
    // if(filters["merchant_order_ref"]){
    //   delete filters["source"];
    // }else{
    //   filters = {};
    // }
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify({environment, page: pageIndex, to: toDate,from: fromDate, pagesize, filters, merchant_keys:uuidsToSend }),
      credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/master-merchant/${iamportKey}/merchants/payment-links`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
  }else{
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify({environment, page: pageIndex,to: toDate, from: fromDate, pagesize, filters }),
      credentials: 'include',
    };
    // Verify if URLSearchParams has polyfill support
    // const queryString = new URLSearchParams({ page: pageIndex, from: '2006-01-02 15:04:05', pagesize })
    return fetch(`${CHAIPAY_API_URL}/merchant/${merchantUUID}/paymentLinks`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
  }
}

function addPaymentLink(merchantUUID, entityDetails) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(entityDetails),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentLink`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function deletePaymentLink(merchantUUID, linkRef) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/paymentLink/${linkRef}/status/cancelled`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function notificationPaymentLink(merchantUUID, linkRef) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentLink/${linkRef}/send-notification`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getSourceList(merchantUUID, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/list-of-sources?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      // if(response.status === 401) {
      //   window.location = window.location.origin + '/logout';
      // }
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

function checkRefPaymentLink(merchantUUID, refLink) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentLink/${refLink}/valid`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchPaymentLinkDetails(merchantUUID, iamportKey, pageRef) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/paymentLink/${pageRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchPaymentLinkTransactions(merchantUUID, iamportKey, environment, pageRef) {
  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify({ uuid: merchantUUID, environment }),
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/paymentLink/${pageRef}/txns-records`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function generateQRCode(iamportKey, pageRef) {
  const requestOptions = { 
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include'
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/paymentLink/${pageRef}/generate-qr-code`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function exportPaymentLinkTransactions(iamportKey, pageData, isMasterMerchant) {
  if(isMasterMerchant){
    if(pageData.merchant_uuids.length > 0){
      pageData.merchant_keys = pageData.merchant_uuids.map(function (item) {
        return item.key;
      });
    }
  }
  delete pageData.merchant_uuids;

  const requestOptions = { 
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(pageData),
  };

  const URI = isMasterMerchant ? `master-merchant/${iamportKey}/merchants/export-payment-links` : `merchant/${iamportKey}/export-paymentLinks`;
  return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "payment-links_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}