import Headers from '../headers';
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const AccountService = {
  createSubUser,
  updateSubUserDetails,
  fetchDetailsForAllSubUsers,
  fetchSubUserDetailsById,
  verifySubUserPermissions,
  setupOrGenerate2FA,
  verify2FAForCurrentUser,
};

// Get List of Sub Users and details
function fetchDetailsForAllSubUsers(chaiPayKey, environment) {

  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${chaiPayKey}/subUser?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// Fetch details of Sub User
function fetchSubUserDetailsById(chaiPayKey, subUserUUid, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  
  return fetch(`${CHAIPAY_API_URL}/merchants/${chaiPayKey}/subUser/${subUserUUid}?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// Create a SubUser
function createSubUser(chaiPayKey, subUserDetails) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(subUserDetails),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${chaiPayKey}/subUser`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// Update SubUser details
function updateSubUserDetails(chaiPayKey, subUserUUid, subUserDetails) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(subUserDetails),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${chaiPayKey}/subUser/${subUserUUid}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// Verify SubUser permissions
function verifySubUserPermissions(chaiPayKey, subUserUUid ,subUserPermissions) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(subUserPermissions),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${chaiPayKey}/subUser/${subUserUUid}/verifyPermissions`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// Setup Or Generate 2FA
function setupOrGenerate2FA(emailID, twoFADetails) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({...twoFADetails, email_address:emailID}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/verification/2FA`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// Verify 2FA
function verify2FAForCurrentUser(emailID, verificationDetails) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({email_address:emailID , ...verificationDetails}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/verification/2FA/verify`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}