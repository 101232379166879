import Headers from '../headers'

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const ReconTransactionService = {
  getReconTransactionListForMerchant,
  // getChannelListForMerchant,
  uploadReconTransactionFile
};

function getReconTransactionListForMerchant(payload) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify(payload),
      credentials: 'include',
    };

    return fetch(`${CHAIPAY_API_URL}/merchants/third_party/transactions/all`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

// function getChannelListForMerchant(merchantUUID, environment) {
//   const requestOptions = {
//     method: 'GET',
//     headers: Headers.apiHeaders(),
//     credentials: 'include',
//   };
//   const queryParams = new URLSearchParams({environment});
//   return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/list-paychannels?` + queryParams, requestOptions).then((response) => {
//     if (response.ok) {
//       return response.json();
//     } else {
//       return response.text().then(text => {
//         throw new Error(text);
//       });
//     }
//   });
// }

function uploadReconTransactionFile(fileData,iamportKey) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(false), 'X-Chaipay-Client-Key': iamportKey },
    body: fileData,
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/third_party/transactions`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}