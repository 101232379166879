import Headers from '../headers';
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const PaymentGatewayService = {
  getPaymentChannelCredentials,
  updatePaymentChannelCredentials,
  getWebhooksData,
  addWebhookData,
  deleteWebhookData,
  updateWebhookData,
  updateWebhookByFormData,
  getPayoutChannelCredentials,
  updatePayoutChannelCredentials,
  updateSecureSecretKeyData
};

function getPaymentChannelCredentials(merchantUUID) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/paychannels`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updatePaymentChannelCredentials(merchantUUID, pmtChannelId, channelDetails) {
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ creds: channelDetails }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/paychannel/${pmtChannelId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getWebhooksData(merchantUUID, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/webhooks?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function addWebhookData(merchantUUID, webhookData) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
    body: JSON.stringify(webhookData),
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/webhooks`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateWebhookData(merchantUUID, webhookData) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(webhookData),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/webhooks/${webhookData.uuid}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getPayoutChannelCredentials(merchantUUID, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/payout-channels?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updatePayoutChannelCredentials(merchantUUID, pmtChannelId, channelDetails) {
  // Channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ creds: channelDetails }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/paychannel/${pmtChannelId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
function updateWebhookByFormData(merchantUUID, key, webhookData) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(webhookData),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${key}/update-webhook`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}


function deleteWebhookData(merchantUUID, linkRef, key) {
  const requestOptions = {
    method: 'DELETE',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${key}/delete-webhook/${linkRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function updateSecureSecretKeyData(merchantUUID, key, secretKeyData) {
  // Channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({ creds: secretKeyData }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${key}/reset-chaiport-securesecret`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}