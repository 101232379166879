import { remittanceBankAccountService } from '../../helpers/backend/remittance_bank_accounts.service';

import {
    FETCH_REMITTANCES_BANK_ACCOUNT,
    POST_REMITTANCES_BANK_ACCOUNT,
    UPDATE_REMITTANCES_BANK_ACCOUNT,
    FETCH_BANK_LIST,
} from '../actions.type';

import {
  ADD_REMITTANCES_BANK_ACCOUNT_IN_STORE,
  TOGGLE_VENDORS_LOADER_IN_STORE,
  CREATE_BANK_DATA_IN_STORE,
  UPDATE_BANK_DATA_IN_STORE,
  ADD_BANK_LIST_IN_STORE
} from '../mutations.type';

//import * as cloneDeep from "lodash.clonedeep";

export const state = {
    content: [],
    isFetchingData: false,
    total_elements: 0,
    total_pages: 0,
    size: 0,
    page: 0,
    //banksAccountData: {},
    banksList:{},
    number_of_elements: 0,
}

export const mutations = {
    [TOGGLE_VENDORS_LOADER_IN_STORE](state, isFetching) {
        state.isFetchingData = isFetching;
    },
    
    [ADD_REMITTANCES_BANK_ACCOUNT_IN_STORE](state, content) {
        state.content = content
      },

    [ADD_BANK_LIST_IN_STORE](state, content) {
        state.banksList = content
      },

    [CREATE_BANK_DATA_IN_STORE](state, bankAccountData) {
        //state.isFetchingData = bankAccountData.isFetchingData;
        //state.content = bankAccountData.content;
        //state.bankAccountData = bankAccountData.content;
        state.bankAccountData = { ...state.bankAccountData, ...bankAccountData };
        // state.total_elements = bankAccountData.total_elements;
        // state.total_pages = bankAccountData.total_pages;
        // state.size = bankAccountData.size;
        // state.page = bankAccountData.page;
        // state.number_of_elements = bankAccountData.number_of_elements;
    },
    [UPDATE_BANK_DATA_IN_STORE](state, bankAccountData) {
        
        //state.bankAccountData = { ...state.bankAccountData, ...bankAccountData };
        state.content.content = state.content.content.map(data => {
            if (data.uuid === bankAccountData.uuid) {
              data.account_number = bankAccountData.AccountNumber;
              data.account_type = bankAccountData.AccountType
              data.active = bankAccountData.active
              data.bank_id = bankAccountData.BankId
              data.bank_name = bankAccountData.BankName
              data.branch = bankAccountData.Branch
              data.bsb_code = bankAccountData.BsbCode
              data.card_number = bankAccountData.CardNumber
              data.country = bankAccountData.Country
              data.currency = bankAccountData.currency
              data.default = bankAccountData.default
              data.first_name = bankAccountData.first_name
              data.last_name = bankAccountData.last_name
              data.phone_number = bankAccountData.phone_number
              data.iban = bankAccountData.Iban
              data.ifsc = bankAccountData.Ifsc
              data.phone_number = bankAccountData.PhoneNumber
              data.remittance_purpose = bankAccountData.RemittancePurpose
              data.remittance_source = bankAccountData.RemittanceSource
              data.routing_number = bankAccountData.RoutingNumber
              data.sort_code = bankAccountData.SortCode
              data.swift = bankAccountData.Swift
              data.transit_code = bankAccountData.TransitCode
              data.wing_money_number = bankAccountData.WingMoneyNumber
              
            }
            return data;
          });
        
    },
    
}


export const actions = {

    [FETCH_REMITTANCES_BANK_ACCOUNT](context, paging) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, true);
            const environment = context.rootState.auth.environment;
            const merchantUUId = context.rootState.auth.currentUser.merchantUuid;
            remittanceBankAccountService.fetchBankAccountTable(merchantUUId, key, paging, environment).then((response) => {
                context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                context.commit(ADD_REMITTANCES_BANK_ACCOUNT_IN_STORE, response);
                resolve(response);
            }).catch((error) => {
                context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },


    [POST_REMITTANCES_BANK_ACCOUNT](context, addBankAccountDetails) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            const environment = context.rootState.auth.environment;
            const merchantUUId = context.rootState.auth.currentUser.merchantUuid;
            addBankAccountDetails['environment'] = environment;
            remittanceBankAccountService.postBankAccountTable(merchantUUId, key, addBankAccountDetails).then((response) => {
                const bankAccountData = response
                context.commit(CREATE_BANK_DATA_IN_STORE, bankAccountData)
                resolve(response);
                context.commit('notification/success', "Bank Account Created Successfully", { root: true })
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(errorObject);
            })
        })
    },

    [UPDATE_REMITTANCES_BANK_ACCOUNT](context, addBankAccountDetails) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            const environment = context.rootState.auth.environment;
            const merchantUUId = context.rootState.auth.currentUser.merchantUuid;
            addBankAccountDetails['environment'] = environment;
            remittanceBankAccountService.updateBankAccountTable(merchantUUId, key, addBankAccountDetails).then((response) => {
                const bankAccountData = response
                context.commit(UPDATE_BANK_DATA_IN_STORE, bankAccountData)
                resolve(response);
                context.commit('notification/success', "Bank Account Updated Successfully", { root: true })
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(errorObject);
            })
        })
    },

    [FETCH_BANK_LIST](context) {
        return new Promise((resolve, reject) => {
            const key = context.rootState.auth.currentUser.iamportKey;
            //context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, true);
            const environment = context.rootState.auth.environment;
            const merchantUUId = context.rootState.auth.currentUser.merchantUuid;
            remittanceBankAccountService.fetchBanksList(merchantUUId, key, environment).then((response) => {
                //context.commit(TOGGLE_VENDORS_LOADER_IN_STORE, false);

                context.commit(ADD_BANK_LIST_IN_STORE, response);
                resolve(response);
            }).catch((error) => {
                console.log(error)
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },

    

}


export const namespaced = true;
