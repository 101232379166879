import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const SettlementsService = {
  fetchSettlementsSummaryResults,
  fetchSettlementsSummaryExpandBy
};
function fetchSettlementsSummaryResults(merchantUUId, iamportKey, pageIndex, pagesize, from_date, to_date, source, frequency, recon_type, environment,filters) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify({ key:iamportKey,recon_type:recon_type,source:source,from_date:from_date,to_date:to_date,frequency:frequency, page: pageIndex, pagesize, environment,filters }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/cross-border/settlement/summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchSettlementsSummaryExpandBy(merchantUUId, iamportKey, pageIndex, pagesize, from_date, to_date, field, frequency, recon_type, environment,filters) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify({ key:iamportKey,recon_type:recon_type,field:field,from_date:from_date,to_date:to_date,frequency:frequency, page: pageIndex, pagesize, environment,filters }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/cross-border/settlement/custom/summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}