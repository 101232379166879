import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const NotificationService = {
  fetchNotifications,
  readNotification,
  readAllNotifications
};

function fetchNotifications(merchantUUID, payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/notifications`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function readNotification(merchantUUID, notificationUUID) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include'
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/notifications/${notificationUUID}/mark-read`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function readAllNotifications(merchantUUID) {
  const requestOptions = {
    method: 'PUT',
    headers: Headers.apiHeaders(),
    credentials: 'include'
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/notifications/mark-all-read`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}