import { SettlementsService } from '../../helpers/backend/settlements.service';
import moment from 'moment';
import {
  FETCH_SETTLEMENTS_SUMMARY_RESULTS,
  FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY,
} from '../actions.type';

import {
  ADD_SETTLEMENTS_SUMMARY_RESULTS_IN_STORE,
  UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE,
} from '../mutations.type';

export const state = {
  isFetchingData: false,
  Summarycontent: [],
  total_elements: 0,
  total_pages: 0,
  size: 0,
  page: 0,
  number_of_elements: 0,
}

export const mutations = {
  [ADD_SETTLEMENTS_SUMMARY_RESULTS_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.Summarycontent = newTransactionData.content;
    state.total_elements = newTransactionData.total_elements;
    state.total_pages = newTransactionData.total_pages;
    state.size = newTransactionData.size;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.number_of_elements;
  },
  [UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE](state, isFetching) {
    state.isFetchingData = isFetching;
  }
}

export const getters = {
  getPaymentLinksEntityDetails(state) {
    return state.entityDetails;
  },
}

export const actions = {
  [FETCH_SETTLEMENTS_SUMMARY_RESULTS]({ commit, rootState }, { pageIndex, pagesize, from_date, to_date, source, frequency, recon_type,filter }) {
    commit(UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      const merchantUUId = rootState.auth.currentUser.merchantUuid;
      SettlementsService.fetchSettlementsSummaryResults(merchantUUId, iamportKey, pageIndex, pagesize, from_date, to_date, source, frequency, recon_type, environment,filter).then((response) => {
        const paylinks_content = response.content.map((paylinks) => {
          var pending_value = 0
          // var remitted_amount_value = 0
          if(paylinks.categories.buckets.length > 0){
            paylinks.categories.buckets.filter((bucket) => {
              if(bucket.key == 'PSP_SETTLED'){
                pending_value = bucket.credit_amount.value
              }
              // else if(bucket.key == 'FULLY_SETTLED'){
              //   remitted_amount_value = bucket.credit_amount.value
              // }
            });
          }
          return {
            date_month: moment(paylinks.key_as_string).format('MMM, YYYY') || "",
            date_week: moment(paylinks.key_as_string).format('MMM Do YYYY') || "",
            country: '',
            payment_channel: '',
            payment_method:'',
            total_txn_amount:(paylinks.total_txn_amount_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            txn_fee:(paylinks.txn_fee_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            wht:(paylinks.wht_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            total_settlement_amount: (paylinks.total_settlement_amount_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            pending_amount:(pending_value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            remitted_amount:(paylinks.net_remit.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            transfer_fee:(paylinks.remit_fee.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            net_remitted_amount:paylinks.net_remit_dc.value,
            date_raw:paylinks.key
          };
        });

        const paymentLinksData = {
          content: paylinks_content,
          total_elements: response.total_elements,
          total_pages: response.total_pages,
          size: response.size,
          page: response.page,
          number_of_elements: response.number_of_elements,
        }
        commit(ADD_SETTLEMENTS_SUMMARY_RESULTS_IN_STORE, paymentLinksData)
        commit(UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE, false)
        resolve(paymentLinksData);
      }).catch((error) => {
        commit(UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY]({ commit, rootState }, { pageIndex, pagesize, from_date, to_date, field, frequency, recon_type ,filter}) {
    commit(UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      const merchantUUId = rootState.auth.currentUser.merchantUuid;
      SettlementsService.fetchSettlementsSummaryExpandBy(merchantUUId, iamportKey, pageIndex, pagesize, from_date, to_date, field, frequency, recon_type, environment,filter).then((response) => {
        const paylinks_content = []
        response.content.map((paylinks) => {
          if(paylinks.channels.buckets.length > 0){
            paylinks.channels.buckets.map((bucketContent) => {
              var pending_value = 0
              // var remitted_amount_value = 0
              if(bucketContent.categories.buckets.length > 0){
                bucketContent.categories.buckets.filter((bucket) => {
                  if(bucket.key == 'PSP_SETTLED'){
                    pending_value = bucket.credit_amount.value
                  }
                  // else if(bucket.key == 'FULLY_SETTLED'){
                  //   remitted_amount_value = bucket.credit_amount.value
                  // }
                });
              }
              paylinks_content.push({
                date_month: moment(paylinks.key_as_string).format('MMM, YYYY') || "",
                date_week: moment(paylinks.key_as_string).format('MMM Do YYYY') || "",
                country: bucketContent.key,
                payment_channel: bucketContent.key,
                payment_method:bucketContent.key,
                total_txn_amount:(bucketContent.total_txn_amount_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                txn_fee:(bucketContent.txn_fee_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                wht:(bucketContent.wht_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                total_settlement_amount:(bucketContent.total_settlement_amount_bc.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                pending_amount:(pending_value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                remitted_amount:(bucketContent.net_remit.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                transfer_fee:(bucketContent.remit_fee.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                net_remitted_amount:bucketContent.net_remit_dc.value,
                date_raw:paylinks.key
              });
            });  
          }
        });  
        // const paylinks_content = response.content.map((paylinks) => {
        //   var pending_value = 0
        //   var remitted_amount_value = 0
        //   if(paylinks.categories.buckets.length > 0){
        //     paylinks.categories.buckets.filter((bucket) => {
        //       if(bucket.key == 'PSP_SETTLED'){
        //         pending_value = bucket.credit_amount.value
        //       }else if(bucket.key == 'FULLY_SETTLED'){
        //         remitted_amount_value = bucket.credit_amount.value
        //       }
        //     });
        //   }
        //   return {
        //     date: moment(paylinks.key_as_string).format('MMM Do YYYY') || "",
        //     country: '',
        //     payment_channel: '',
        //     payment_method:'',
        //     total_txn_amount:paylinks.total_txn_amount_bc.value,
        //     txn_fee:paylinks.txn_fee_bc.value,
        //     wht:paylinks.wht_bc.value,
        //     total_settlement_amount: paylinks.total_settlement_amount_bc.value,
        //     pending_amount: pending_value,
        //     remitted_amount: remitted_amount_value,
        //     transfer_fee:paylinks.remit_fee.value,
        //     net_remitted_amount:paylinks.net_remit.value,
        //   };
        // });

        const paymentLinksData = {
          content: paylinks_content,
          total_elements: response.total_elements,
          total_pages: response.total_pages,
          size: response.size,
          page: response.page,
          number_of_elements: response.number_of_elements,
        }
        commit(ADD_SETTLEMENTS_SUMMARY_RESULTS_IN_STORE, paymentLinksData)
        commit(UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE, false)
        resolve(paymentLinksData);
      }).catch((error) => {
        commit(UPDATE_SETTLEMENTS_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  }
}

export const namespaced = true;
