import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const remittanceBankAccountService = {
  fetchBankAccountTable,
  postBankAccountTable,
  updateBankAccountTable,
  fetchBanksList
};
function fetchBankAccountTable(merchantUUId, key, paging, environment) {
    paging.pageIndex = paging.pageIndex || 1;
    paging.pagesize = paging.pagesize || 100;
    const requestOptions = {
        method: 'GET',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(),
        credentials: 'include',
    };
    const queryParams = new URLSearchParams({environment, page: paging.pageIndex, pagesize: paging.pagesize});
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/cross-border/bank-account?` + queryParams, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}

function postBankAccountTable(merchantUUId, key, addBankAccountDetails) {
    const requestOptions = {
        method: 'POST',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(addBankAccountDetails),
        credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/cross-border/bank-account`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}


function updateBankAccountTable(merchantUUId, key, addBankAccountDetails) {
    console.log(addBankAccountDetails)
    const uuid = addBankAccountDetails.uuid;
    const requestOptions = {
        method: 'PUT',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(addBankAccountDetails),
        credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/cross-border/bank-account/${uuid}`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}

function fetchBanksList(merchantUUId, key, environment) {
    const requestOptions = {
        method: 'GET',
        headers: Headers.apiHeaders(),
        body: JSON.stringify(),
        credentials: 'include',
    };
    const queryParams = new URLSearchParams({environment});
    return fetch(`${CHAIPAY_API_URL}/merchants/${key}/cross-border/bank-list/KR?` + queryParams, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(text => {
                if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                    window.location = window.location.origin + '/logout';
                }
                throw new Error(text);
            });
        }
    });
}


