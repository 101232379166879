import { ReconTransactionService } from '../../helpers/backend/recon_transaction.service';
import {
  FETCH_RECON_TRANSACTIONS,
//   FETCH_CHANNEL_LIST,
  RECON_TRANSACTION_FILE_UPLOAD,
} from '../actions.type';
import {
	ADD_RECON_TRANSACTIONS_IN_STORE,
	// ADD_CHANNEL_LIST_IN_STORE,
} from '../mutations.type';

export const state = {
	content: [],
	total_elements: 0,
	total_pages: 0,
	size: 0,
	page: 0

}

export const mutations = {

	[ADD_RECON_TRANSACTIONS_IN_STORE](state, newTransactionData) {
		state.content = newTransactionData.data;
		state.total_elements = newTransactionData.total_elements;
		state.total_pages = newTransactionData.total_pages;
		state.size = newTransactionData.size;
		state.page = newTransactionData.page;
	},
}

export const getters = {
	getTransactionsData(state) {
		return state.transactionData;
	},
	getTransactionsDataForSelectedTransactionID(state) {
		const transactionID = state.selectedTransactionID;
		if(transactionID == undefined) {
			return undefined;
		} else {
      return state.content.find((item) => item.order_ref === transactionID);
    }
	},
	getRefundDataForSelectedTransactionID(state) {
    return state.selectedTransactionID && state.refundData ? state.refundData : undefined;
	}
}

export const actions = {

	[FETCH_RECON_TRANSACTIONS]({ commit, rootState }, payload) {
		return new Promise((resolve, reject) => {
			payload.merchant_key = rootState.auth.currentUser.iamportKey;
			payload.environment = rootState.auth.environment;

			ReconTransactionService.getReconTransactionListForMerchant(payload).then((response) => {
				if(response.data) {
					commit(ADD_RECON_TRANSACTIONS_IN_STORE, response)
				}
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[RECON_TRANSACTION_FILE_UPLOAD](context, { fileData }) {
		const iamportKey = context.rootState.auth.currentUser.iamportKey;
		const environment = context.rootState.auth.environment;
		fileData['environment'] = environment;
		fileData.append('environment', environment);
		return new Promise((resolve, reject) => {
			ReconTransactionService.uploadReconTransactionFile(fileData, iamportKey).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	// [FETCH_CHANNEL_LIST]({ commit, rootState }) {
	// 	return new Promise((resolve, reject) => {
	// 		const merchantUUID = rootState.auth.currentUser.merchantUuid;
	// 		const environment = rootState.auth.environment;
	// 		ReconTransactionService.getChannelListForMerchant(merchantUUID, environment).then((response) => {
	// 			commit(ADD_CHANNEL_LIST_IN_STORE, response)
	// 			resolve(response);
	// 		}).catch((error) => {
	// 			const errorObject = JSON.parse(error.message)
	// 			commit('notification/error', errorObject.Message, { root: true })
	// 			reject(error);
	// 		})
	// 	})
	// },
}

export const namespaced = true;
