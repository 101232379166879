import { SmartRoutingService } from "../../helpers/backend/smart_routing.service";
import { CHANGE_DEFAULT_ROUTING_DETAILS, DELETE_ROUTING_DETAILS, FETCH_ALL_PAYMENT_CHANNEL_LIST, FETCH_TRANSACTION_ROUTES, POST_ROUTING_DETAILS, UPDATE_ROUTING_DETAILS } from "../actions.type"
import { ADD_SMART_ROUTING_LIST_IN_STORE, ADD_PAYMENT_CHANNELS_IN_STORE } from "../mutations.type";

export const state = {
	content: null,
	paymentChannels: []
}

export const mutations = {
	[ADD_SMART_ROUTING_LIST_IN_STORE](state, data) {
		state.content = data;
	},
	[ADD_PAYMENT_CHANNELS_IN_STORE](state, paymentChannelList) {
		state.paymentChannels = paymentChannelList.filter(channel => channel.is_enabled);
	}
}

export const actions = {
	[FETCH_TRANSACTION_ROUTES](context) {
		const iamportKey = context.rootState.auth.currentUser.iamportKey;
		const environment = context.rootState.auth.environment;
		return new Promise((resolve, reject) => {
			SmartRoutingService.fetchTransactionRoutes(iamportKey, environment).then((response) => {
				context.commit(ADD_SMART_ROUTING_LIST_IN_STORE, response.content.data)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
	[POST_ROUTING_DETAILS](context, formData) {
		formData.portone_key = context.rootState.auth.currentUser.iamportKey;
		formData.environment = context.rootState.auth.environment;

		return new Promise((resolve, reject) => {
			SmartRoutingService.postRouteDetails(formData).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[UPDATE_ROUTING_DETAILS](context, { formData, routing_ref }) {
		const iamportKey = context.rootState.auth.currentUser.iamportKey;

		return new Promise((resolve, reject) => {
			SmartRoutingService.updateRouteDetails(iamportKey, formData, routing_ref).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[DELETE_ROUTING_DETAILS](context, routing_ref) {
		const iamportKey = context.rootState.auth.currentUser.iamportKey;

		return new Promise((resolve, reject) => {
			SmartRoutingService.deleteRouteDetails(iamportKey, routing_ref).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[FETCH_ALL_PAYMENT_CHANNEL_LIST](context) {
		const merchantUuid = context.rootState.auth.currentUser.merchantUuid;
		const environment = context.rootState.auth.environment;

		return new Promise((resolve, reject) => {
			SmartRoutingService.fetchAllPaymentChannels(merchantUuid, environment).then((response) => {
				context.commit(ADD_PAYMENT_CHANNELS_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[CHANGE_DEFAULT_ROUTING_DETAILS](context, routing_ref) {
		const iamportKey = context.rootState.auth.currentUser.iamportKey;

		return new Promise((resolve, reject) => {
			SmartRoutingService.setDefaultRouteDetails(iamportKey, routing_ref).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
}