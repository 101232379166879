import { TransactionService } from '../../helpers/backend/transaction.service';
import { RefundService } from '../../helpers/backend/refund.service';

import {
  FETCH_TRANSACTIONS,
  FETCH_CHANNEL_LIST,
  TRANSACTION_FILE_UPLOAD,
  FETCH_WEBHOOK_DATA_FOR_TRANSACTION,
  RESEND_WEBHOOK_DATA_FOR_TRANSACTION,
  SET_SELECTED_TRANSACTION_ID,
  CREATE_REFUND_FOR_TRANSACTION,
  FETCH_REFUND_DATA_FOR_TRANSACTION,
  EXPORT_TRANSACTIONS_INTO_EXCEL,
  FETCH_TRANSACTION_DETAILS,
  UPDATE_TRXN_STATUS,
  UPDATE_REFUND_STATUS,
  FETCH_FAILOVER_DATA_FOR_TRANSACTION,
  FETCH_COLUMN_LIST,
  UPDATE_COLUMN_LIST,
  RESET_COLUMN_LIST
} from '../actions.type';
import {
	ADD_TRANSACTIONS_IN_STORE,
	ADD_CHANNEL_LIST_IN_STORE,
	UPDATE_TRXN_FETCHING_STATE,
	SET_SELECTED_TRANSACTION_ID_IN_STORE,
	SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE,
	ADD_REFUND_DATA_FOR_SELECTED_TRANSACTION_IN_STORE,
	SET_TRANSACTION_DETAILS_IN_STORE,
	UPDATE_TRXN_STATUS_IN_STORE,
	SET_FAILOVER_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE
} from '../mutations.type';
import { HmacSHA256 } from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';

export const state = {
	isFetchingData: false,
	content: [],
	total_elements: 0,
	total_pages: 0,
	size: 0,
	page: 0,
	number_of_elements: 0,
	selectedTransactionID: undefined,
	paymentChannelList:[],
	webhookDetailsForSelectedTransaction: {
		channelEvents: [],
		webhookData: [],
	},
	refundData: null,
	transactionData: {},
	failoverTrasactions: null

}

export const mutations = {

	[ADD_TRANSACTIONS_IN_STORE](state, newTransactionData) {
		state.isFetchingData = newTransactionData.isFetchingData;
		state.content = newTransactionData.content;
		state.total_elements = newTransactionData.total_elements;
		state.total_pages = newTransactionData.total_pages;
		state.size = newTransactionData.size;
		state.page = newTransactionData.page;
		state.number_of_elements = newTransactionData.number_of_elements;
	},
	[ADD_CHANNEL_LIST_IN_STORE](state, newChannelListData) {
		state.paymentChannelList = newChannelListData;
	},
	[UPDATE_TRXN_FETCHING_STATE](state, isFetching) {
		state.isFetchingData = isFetching;
	},
	[SET_SELECTED_TRANSACTION_ID_IN_STORE](state, transactionID) {
		if(transactionID == undefined) {
			state.webhookDetailsForSelectedTransaction = [];
		}
		state.selectedTransactionID = transactionID;
	},
	[ADD_REFUND_DATA_FOR_SELECTED_TRANSACTION_IN_STORE](state, {transactionID, refundDetails}) {
		if(transactionID == undefined) {
			state.refundData = null;
		}
		state.refundData = refundDetails;
	},
	[SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE](state, webhookData) {
		state.webhookDetailsForSelectedTransaction = webhookData ? webhookData : {channelEvents: [],webhookData: []};
	},
	[SET_FAILOVER_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE](state, failoverData) {
		state.failoverTrasactions = failoverData;
	},
	[SET_TRANSACTION_DETAILS_IN_STORE](state, transactionData) {
		if(transactionData && transactionData.direct_bank_transfer_details && transactionData.direct_bank_transfer_details.payment_slip && !Array.isArray(transactionData.direct_bank_transfer_details.payment_slip)) {
			transactionData.direct_bank_transfer_details.payment_slip = [transactionData.direct_bank_transfer_details.payment_slip];
		}
		state.transactionData = transactionData;
	},
	[UPDATE_TRXN_STATUS_IN_STORE](state, status) {
		state.transactionData.status = status;
	}
}

export const getters = {
	getTransactionsData(state) {
		return state.transactionData;
	},
	getTransactionsDataForSelectedTransactionID(state) {
		const transactionID = state.selectedTransactionID;
		if(transactionID == undefined) {
			return undefined;
		} else {
      return state.content.find((item) => item.order_ref === transactionID);
    }
	},
	getRefundDataForSelectedTransactionID(state) {
    return state.selectedTransactionID && state.refundData ? state.refundData : undefined;
	}
}

export const actions = {

	[FETCH_TRANSACTIONS]({ commit, rootState }, data) {
		commit(UPDATE_TRXN_FETCHING_STATE, true)
		return new Promise((resolve, reject) => {
			const merchantUUID = rootState.auth.currentUser.merchantUuid;
			const iamportKey = rootState.auth.currentUser.iamportKey;
			data.payload.environment = rootState.auth.environment;
			TransactionService.getTransactionListForMerchant(merchantUUID, iamportKey, data).then((response) => {
				if(response.content === null) {
					response.content = [];
				}

				commit(ADD_TRANSACTIONS_IN_STORE, response)
				commit(UPDATE_TRXN_FETCHING_STATE, false)
				resolve(response);
			}).catch((error) => {
				commit(UPDATE_TRXN_FETCHING_STATE, false)
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[TRANSACTION_FILE_UPLOAD](context, { fileData }) {
		const iamportKey = context.rootState.auth.currentUser.iamportKey;
		const environment = context.rootState.auth.environment;
		fileData['environment'] = environment;
		fileData.append('environment', environment);
		return new Promise((resolve, reject) => {
			TransactionService.uploadTransactionFile(fileData, iamportKey).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				context.commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[FETCH_COLUMN_LIST]({ rootState }) {
		return new Promise((resolve, reject) => {
			const subUserUuid = rootState.auth.currentUser.subUserUuid;
			const iamportKey = rootState.auth.currentUser.iamportKey;
			const environment = rootState.auth.environment;
			TransactionService.getColumnListForMerchant(subUserUuid, iamportKey, environment).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	[UPDATE_COLUMN_LIST]({ rootState }, payload) {
		return new Promise((resolve, reject) => {
			const subUserUuid = rootState.auth.currentUser.subUserUuid;
			const iamportKey = rootState.auth.currentUser.iamportKey;
			payload.environment = rootState.auth.environment;
			TransactionService.updateColumnListForMerchant(subUserUuid, iamportKey, payload).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	[RESET_COLUMN_LIST]({ rootState }) {
		return new Promise((resolve, reject) => {
			const subUserUuid = rootState.auth.currentUser.subUserUuid;
			const iamportKey = rootState.auth.currentUser.iamportKey;
			const environment = rootState.auth.environment;
			TransactionService.resetColumnListForMerchant(subUserUuid, iamportKey, environment).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	[FETCH_CHANNEL_LIST]({ commit, rootState }) {
		return new Promise((resolve, reject) => {
			const merchantUUID = rootState.auth.currentUser.merchantUuid;
			const environment = rootState.auth.environment;
			TransactionService.getChannelListForMerchant(merchantUUID, environment).then((response) => {
				commit(ADD_CHANNEL_LIST_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},


	[FETCH_WEBHOOK_DATA_FOR_TRANSACTION]({ commit, rootState }, { order_ref, submerchant_key }) {
		return new Promise((resolve, reject) => {
			const merchantUUID = rootState.auth.currentUser.merchantUuid;
			const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchant_key : rootState.auth.currentUser.iamportKey;
			commit(SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, null)
			TransactionService.fetchWebhookDataByTransactionId(merchantUUID, iamportKey, order_ref).then((response) => {
				if(response === null) {
					response["webhookData"] = [];
					response["channelEvents"] = [];
				}

				commit(SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				commit(SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, null)
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[FETCH_FAILOVER_DATA_FOR_TRANSACTION]({ commit, rootState }, { merchant_order_ref, submerchant_key }) {
		return new Promise((resolve, reject) => {
			const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchant_key : rootState.auth.currentUser.iamportKey;
			TransactionService.fetchFailoverData(iamportKey, merchant_order_ref).then((response) => {
				commit(SET_FAILOVER_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, response.data)
				resolve(response);
			}).catch((error) => {
				commit(SET_FAILOVER_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, null)
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[RESEND_WEBHOOK_DATA_FOR_TRANSACTION](context, order_ref ) {
        return new Promise((resolve, reject) => {
            const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
            const iamportKey = context.rootState.auth.currentUser.iamportKey;
            TransactionService.resendWebhookDataByTransactionId(merchantUUID, iamportKey, order_ref).then((res) => {
                if(res.status_reason === "SUCCESS"){
					setTimeout(() => { 
                    context.dispatch(`${FETCH_WEBHOOK_DATA_FOR_TRANSACTION}`, {
						order_ref: order_ref
					})}, 1000);
                }
                resolve(res);
            }).catch((error) => {
                // commit(SET_WEBHOOK_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, null)
                console.log("error", error)
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },


	[CREATE_REFUND_FOR_TRANSACTION]({ rootState, commit }, {order_ref, refundData}) {
		return new Promise((resolve, reject) => {
			refundData.environment = rootState.auth.environment;
			RefundService.createRefundForTransaction(order_ref, refundData).then((response) => {
				commit(ADD_REFUND_DATA_FOR_SELECTED_TRANSACTION_IN_STORE, {transactionId: order_ref, refundDetails: response})
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message);
				commit('notification/error', errorObject.Message, { root: true })
				reject(errorObject);
			})
		})
	},

	[FETCH_REFUND_DATA_FOR_TRANSACTION]({ commit }, order_ref) {
		return new Promise((resolve, reject) => {
			RefundService.fetchRefundDetailsForTransaction(order_ref).then((response) => {
				commit(ADD_REFUND_DATA_FOR_SELECTED_TRANSACTION_IN_STORE, {transactionId: order_ref, refundDetails: response})
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[SET_SELECTED_TRANSACTION_ID]({ commit }, transactionID ) {
		commit(SET_SELECTED_TRANSACTION_ID_IN_STORE, transactionID)
	},

	[EXPORT_TRANSACTIONS_INTO_EXCEL]({ commit, rootState }, { filters, sorting_data, isMasterMerchant, merchant_uuids, fromDate, toDate }) {
		return new Promise((resolve, reject) => {
		const merchantUUID = rootState.auth.currentUser.merchantUuid;
		const iamportKey = rootState.auth.currentUser.iamportKey;
		const environment = rootState.auth.environment;
		TransactionService.exportTransactionDataForMerchant(merchantUUID, iamportKey, filters, sorting_data, isMasterMerchant, merchant_uuids, environment, fromDate, toDate).then((response) => {
			resolve(response);
		}).catch((error) => {
			const errorObject = JSON.parse(error.message)
			commit('notification/error', errorObject.Message, { root: true })
			reject(error);
		})
		})
	},
	[FETCH_TRANSACTION_DETAILS]({ commit, rootState }, { order_ref, submerchant_key }) {
		return new Promise((resolve, reject) => {
			const merchantUUID = rootState.auth.currentUser.merchantUuid;
			const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchant_key : rootState.auth.currentUser.iamportKey;
			TransactionService.fetchTransactionDetails(merchantUUID, iamportKey, order_ref).then((response) => {
				commit(SET_TRANSACTION_DETAILS_IN_STORE, response.content);
				if(!response.routing_enabled) {
					commit(SET_FAILOVER_DETAILS_FOR_SELECTED_TRANSACTION_ID_IN_STORE, null)
				}
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
	[UPDATE_TRXN_STATUS]({commit, rootState}, { status, merchantOrderRef }) {
		return new Promise((resolve, reject) => {
			const paymentStatus = status ? "Success" : "Failed";
			const merchantUUID = rootState.auth.currentUser.merchantUuid;

			const { iamportKey, iamportSecret } = rootState.auth.currentUser;

			const mainParams = "client_key="+encodeURIComponent(iamportKey)+"&merchant_order_ref="+encodeURIComponent(merchantOrderRef)+"&status="+encodeURIComponent(paymentStatus);

			const hash = HmacSHA256(mainParams, iamportSecret);
			const signatureHash = Base64.stringify(hash);

			const data = {
				"status": paymentStatus,
				"merchant_order_ref": merchantOrderRef,
				"signature": signatureHash
			}

			TransactionService.updateTransactionStatus(merchantUUID, data).then((response) => {
				commit(UPDATE_TRXN_STATUS_IN_STORE, paymentStatus);
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
	[UPDATE_REFUND_STATUS]({commit, rootState}, { status, refundId }) {
		return new Promise((resolve, reject) => {
			const paymentStatus = status ? "SUCCESS" : "FAIL";

			const { iamportKey, iamportSecret } = rootState.auth.currentUser;

			const mainParams = "client_key="+encodeURIComponent(iamportKey)+"&refund_id="+encodeURIComponent(refundId)+"&status="+encodeURIComponent(paymentStatus);

			const hash = HmacSHA256(mainParams, iamportSecret);
			const signatureHash = Base64.stringify(hash);

			const data = {
				"status": paymentStatus,
				"refund_id": refundId,
				"signature": signatureHash
			}

			RefundService.updateRefundStatus(data).then((response) => {
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	}
}

export const namespaced = true;
