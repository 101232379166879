import { DashboardService } from '../../helpers/backend/dashboard.service';
import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_TRANSACTION_STATUS,
  FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS_METRICS,
  FETCH_TRANSACTION_STATISTICS,
  FETCH_TRANSACTION_STATS,
  FETCH_TRANSACTION_VOLUME,
  FETCH_TRANSACTION_VOLUME_SEASONALITY,
  FETCH_TRANSACTION_VOLUME_STACKED_BAR,
  FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS,
  FETCH_LATEST_TXNS
} from '../actions.type';
import {
    ADD_TRANSACTION_STATS_IN_STORE, 
    ADD_TRANSACTION_STATISTICS_IN_STORE,
    ADD_TRANSACTION_STATUS_IN_STORE,
    ADD_TRANSACTION_VOLUME_IN_STORE,
    ADD_TRANSACTION_SEASONALITY_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE_NEW,
    ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE,
    ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE_NEW,
    ADD_TRANSACTION_STACKED_BAR_IN_STORE,
    UPDATE_DASHBOARD_FETCHING_STATE,
    ADD_LATEST_TXNS_IN_STORE
} from '../mutations.type';

export const state = {
  txn_stats:  {},
  txn_status:{},
  txn_vol:  { labels: [], datasets: [] },
  txn_vol_seasonality:  { labels: [], datasets: [] },
  txn_vol_by_status:  { labels: [], datasets: [] },
  txn_vol_by_pmt_channel:  { labels: [], datasets: [] },
  txn_vol_by_pmt_channel_new:  {
    labels: [ 'No data'],
    datasets: [{
      label: 'pie chart',
      data: [1],
      count_data:[1],
      backgroundColor: [
        '#d2dee2'
      ],
    }]
  },
  txn_vol_by_pmt_method:  { labels: [], datasets: [] },
  txn_vol_by_pmt_method_new: {
    labels: [ 'No data'],
    datasets: [{
      label: 'pie chart',
      data: [1],
      count_data:[1],
      backgroundColor: [
        '#d2dee2'
      ],
    }]
  },
  txn_vol_stacked_bar:  { labels: [], datasets: [] },
  txn_vol_statistics_bar:  { labels: [], datasets: [] },
  is_fetching_stacked_bar_data:false,
  is_fetching_status_data:false,
  is_fetching_pieChart_data:false,
  is_fetching_txn_stats_data: false,
  lates_txn:[]
}

export const mutations = {
  [ADD_TRANSACTION_STATS_IN_STORE](state, stats) {
    state.txn_stats = stats;
  },
  [ADD_LATEST_TXNS_IN_STORE](state, newTransactionData) {
    state.lates_txn = newTransactionData;
  },
  [ADD_TRANSACTION_STATUS_IN_STORE](state, stats) {
    state.txn_status = stats;
  },
  [ADD_TRANSACTION_STATISTICS_IN_STORE](state, stats) {
    state.txn_vol_statistics_bar = stats;
  },
  [ADD_TRANSACTION_VOLUME_IN_STORE](state, txnVol) {
    state.txn_vol = txnVol;
  },
  [ADD_TRANSACTION_SEASONALITY_IN_STORE](state, txnVolSeasonality) {
    state.txn_vol_seasonality = txnVolSeasonality;
  },
  [ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE](state, txnVolByStatus) {
    state.txn_vol_by_status = txnVolByStatus;
  },
  [ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE](state, txnVolByChannel) {
    state.txn_vol_by_pmt_channel = txnVolByChannel;
  },
  [ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE_NEW](state, txnVolByChannel) {
    state.txn_vol_by_pmt_channel_new = cloneDeep(txnVolByChannel);
  },
  [ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE](state, txnVolByMethod) {
    state.txn_vol_by_pmt_method = txnVolByMethod;
  },
  [ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE_NEW](state, txnVolByMethod) {
    state.txn_vol_by_pmt_method_new = cloneDeep(txnVolByMethod);
  },
  [ADD_TRANSACTION_STACKED_BAR_IN_STORE](state, txnVolForStackedBar) {
    state.txn_vol_stacked_bar = txnVolForStackedBar;
  },
  [UPDATE_DASHBOARD_FETCHING_STATE](state, felchedState) {
    if(felchedState.type == "stack_bar"){
      state.is_fetching_stacked_bar_data = felchedState.value;
    }else if(felchedState.type == 'status'){
      state.is_fetching_status_data = felchedState.value;
    }else if(felchedState.type == 'pieChart'){
      state.is_fetching_pieChart_data = felchedState.value;
    }else if(felchedState.type == 'txn_stats') {
      state.is_fetching_txn_stats_data = felchedState.value;
    }
  }
}

export const getters = {
  isNavIsFatching(state) {
    return state.is_fetching_stacked_bar_data || state.is_fetching_status_data || state.is_fetching_pieChart_data ? true : false;
  },
}

export const actions = {

  [FETCH_TRANSACTION_STATS](context, {isMasterMerchant, merchant_uuids, currency, from_date, to_date} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'txn_stats'});
      DashboardService.fetchTransactionsStats(merchantUUID, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date).then((stats) => {
        context.commit(ADD_TRANSACTION_STATS_IN_STORE, stats)
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'txn_stats'});
        resolve(stats);
      }).catch((error) => {
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'txn_stats'});
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_LATEST_TXNS](context, {isMasterMerchant, merchant_uuids} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      DashboardService.fetchLates_txn(merchantUUID, isMasterMerchant, merchant_uuids, environment).then((response) => {
        if(response.content === null) {
					response.content = [];
				}
        context.commit(ADD_LATEST_TXNS_IN_STORE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_STATISTICS](context, {isMasterMerchant, merchant_uuids, currency, from_date, to_date, time_bracket} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'stack_bar'});
      DashboardService.fetchTransactionsStatistics(merchantUUID, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date, time_bracket).then((stats) => {
        context.commit(ADD_TRANSACTION_STATISTICS_IN_STORE, stats)
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'stack_bar'});
        resolve(stats);
      }).catch((error) => {
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'stack_bar'});
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_STATUS](context, {isMasterMerchant, merchant_uuids, currency, from_date, to_date} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'status'});
      DashboardService.fetchTransactionsStatus(merchantUUID, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date).then((stats) => {
        context.commit(ADD_TRANSACTION_STATUS_IN_STORE, stats)
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'status'});
        resolve(stats);
      }).catch((error) => {
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'status'});
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS](context, {filter, aggtype, isMasterMerchant, merchant_uuids, currency} = {}) {
    // context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'pieChart'});
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      DashboardService.fetchTransactionVolForAllPieCharts(merchantUUID, filter, aggtype, isMasterMerchant, merchant_uuids, environment, currency).then((txnData) => {
        // context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'pieChart'});
        context.commit(ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE, txnData.txnVolumeByChannel)
        context.commit(ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE, txnData.txnVolumeByMethod)
        context.commit(ADD_TRANSACTION_VOLUME_BY_STATUS_IN_STORE, txnData.txnVolumeByStatus)
        resolve(txnData);
      }).catch((error) => {
        // context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'pieChart'});
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS_METRICS](context, {isMasterMerchant, merchant_uuids, currency, from_date, to_date} = {}) {
    context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'pieChart'});
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      DashboardService.fetchTransactionVolForAllPieChartsMetrics(merchantUUID, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date).then((txnData) => {
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'pieChart'});
        context.commit(ADD_TRANSACTION_VOLUME_BY_CHANNEL_IN_STORE_NEW, txnData.txnVolumeByChannel)
        context.commit(ADD_TRANSACTION_VOLUME_BY_METHOD_IN_STORE_NEW, txnData.txnVolumeByMethod)
        resolve(txnData);
      }).catch((error) => {
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'pieChart'});
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_VOLUME](context, {filter, isMasterMerchant, merchant_uuids, currency} = {}) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      DashboardService.fetchTransactionVolume(merchantUUID, filter, isMasterMerchant, merchant_uuids, environment, currency).then((response) => {
        context.commit(ADD_TRANSACTION_VOLUME_IN_STORE, response.txnVolume)
        resolve(response.txnVolume);
      }).catch((error) => {
        console.log('txnVolume error', error)
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_VOLUME_SEASONALITY](context, {filter, isMasterMerchant, merchant_uuids, currency} = {}) {
    context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'seasonality'});
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      DashboardService.fetchTransactionSeasonality(merchantUUID, filter, isMasterMerchant, merchant_uuids, environment, currency).then((response) => {
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'seasonality'});
        context.commit(ADD_TRANSACTION_SEASONALITY_IN_STORE, response.txnSeasonality)
        resolve(response.txnSeasonality);
      }).catch((error) => {
        console.log('txnSeasonality error', error)
        context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'seasonality'});
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_TRANSACTION_VOLUME_STACKED_BAR](context, {filter, isMasterMerchant, merchant_uuids, currency} = {}) {
    // context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:true,type:'stack_bar'});
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      const environment = context.rootState.auth.environment;
      DashboardService.fetchTransactionStackedBar(merchantUUID, filter, isMasterMerchant, merchant_uuids, environment, currency).then((response) => {
        // context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'stack_bar'});
        context.commit(ADD_TRANSACTION_STACKED_BAR_IN_STORE, response.chartData)
        resolve(response.chartData);
      }).catch((error) => {
        console.log('txnStackedBar error', error)
        //TODO: REMOVE THIS
        // context.commit(UPDATE_DASHBOARD_FETCHING_STATE, {value:false,type:'stack_bar'});
        context.commit(ADD_TRANSACTION_STACKED_BAR_IN_STORE, undefined)
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },
}

export const namespaced = true;
