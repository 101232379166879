import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

import store from '@/state/store';
import routes from './routes';
import { FETCH_SUB_USER_DETAILS_BY_ID } from '../state/actions.type';

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next()


    if(routeTo.path === '/logout') {
      next()
    }

    // If auth is required and the user is logged in...
    // if (store.getters['auth/loggedIn']) {
    //   // Validate the local user token...
    //   return store.dispatch('auth/validate').then((validUser) => {
    //     // Then continue if the token still represents a valid user,
    //     // otherwise redirect to login.
    //     validUser ? next() : redirectToLogin()
    //   })
    // }
    // console.log({routeTo, routeFrom})

    if (store.getters['auth/loggedIn']) {
      // Validate the local user token...
      return store.dispatch(`accounts/${FETCH_SUB_USER_DETAILS_BY_ID}`).then((mchDetails) => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        const env = mchDetails.is_live_mode ? "live" : "sandbox";
        store.dispatch(`auth/setEnvironment`, env)
        // store.dispatch(`accounts/${FETCH_SUB_USER_DETAILS_BY_ID}`)
        if(mchDetails && mchDetails.is_email_verified) {
          next()
        } else {
          if(routeTo.path === '/pages/verify-email' || routeTo.path === '/logout') {
            next()
          } else {
            // next('/pages/verify-email')
            router.push('/pages/verify-email').catch(() => {})
          }
        }
      }).catch((error) => {
        console.log('error on feting sub user data', error)
        // session expired, logout from everything
        if(routeFrom.path === '/logout' || routeTo.path === '/logout') {
          next()
        } else {
          router.push('/logout').catch(() => {})
        }
      })
    }

    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    redirectToLogin()

    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-inner-declarations
    function redirectToLogin() {
      // Pass the original route to the login component
      next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
    }
  // } else {
    // const publicPages = ['/login', '/register', '/forgot-password'];
    // const authpage = !publicPages.includes(routeTo.path);
    // const loggeduser = localStorage.getItem('auth.currentUser');

    // if (authpage && !loggeduser) {
    //   return next('/login');
    // }

    // next();
  // }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    let title = 'PortOne Merchant Portal';
    if(to.meta.title) {
      title = `${title} | ${to.meta.title}`;
    }
    document.title = title;
  });
});
export default router
