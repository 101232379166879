export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import Headers from '../headers';

export const AccountService = {
  fetchAccountStatement,
  downloadExcelStatement
};

  function fetchAccountStatement(merchantUUID, { dates, account_no }) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(dates),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/payout/va/${account_no}/statement`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function downloadExcelStatement(dates, account_no, environment) {
  const requestOptions = {
    method: 'GET',
    headers: Headers.apiHeaders(false),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment, from: dates.from, to: dates.to});
  return fetch(`${CHAIPAY_API_URL}/merchants/payout/va/${account_no}/download-statement?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "account-statement_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}