import { SubscriptionService } from "../../helpers/backend/subscription.service";
import { DELETE_SUBSCRIPTION, FETCH_ALL_PRICE, FETCH_SUBSCRIPTION_DETAILS, FETCH_SUBSCRIPTION_LIST, FETCH_SUBSCRIPTION_TRANSACTIONS, POST_COLLECT_PAYMENT, POST_SUBSCRIPTION } from "../actions.type";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { SET_SUBSCRIPTION_DATA_IN_STORE } from "../mutations.type";

export const state = {
    content: [],
    number_of_elements: 0,
    page: 0,
    size: 0,
    total_elements: 0,
    total_pages: 0
}

export const mutations = {
    [SET_SUBSCRIPTION_DATA_IN_STORE](state, data) {
      state.content = data.content;
      state.number_of_elements = data.number_of_elements;
      state.page = data.page;
      state.size = data.size;
      state.total_elements = data.total_elements;
      state.total_pages = data.total_pages;
    },
}

export const actions = {

    [POST_SUBSCRIPTION](context, newEntityDetails) {
      return new Promise((resolve, reject) => {
        const environment = context.rootState.auth.environment;

        const { iamportKey, iamportSecret } = context.rootState.auth.currentUser;

        const merchantOrderRef = 'Subscription_'+ new Date().getTime()

        const mainParams = "&client_key="+encodeURIComponent(iamportKey)+"&currency="+encodeURIComponent(newEntityDetails.price.currency)+"&failure_url="+encodeURIComponent(newEntityDetails.failure_url)+"&merchant_order_ref="+encodeURIComponent(merchantOrderRef)+"&quantity="+encodeURIComponent(newEntityDetails.quantity)+"&success_url="+encodeURIComponent(newEntityDetails.success_url);

        const hash = hmacSHA256(mainParams, iamportSecret);

        const signatureHash = Base64.stringify(hash);

        newEntityDetails.currency = newEntityDetails.price.currency;

        newEntityDetails.price_order_ref = newEntityDetails.price.order_ref;
        
        const formData = {
            ...newEntityDetails,
            "chaipay_key": iamportKey,
            "signature_hash": signatureHash,
            "merchant_order_ref": merchantOrderRef,
            "source": "checkout",
            environment: environment
        };

        delete formData.price;

        SubscriptionService.addSubscription(formData).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },

    [FETCH_SUBSCRIPTION_LIST](context, payload) {
        return new Promise((resolve, reject) => {
          payload.environment = context.rootState.auth.environment;
          const merchantUuid = context.rootState.auth.currentUser.merchantUuid;
          const iamportKey = context.rootState.auth.currentUser.iamportKey;
          const isMasterMerchant = context.rootState.auth.currentUser.is_master_merchant;

          SubscriptionService.fetchSubscriptionList(merchantUuid, payload, isMasterMerchant, iamportKey).then((response) => {
            context.commit(SET_SUBSCRIPTION_DATA_IN_STORE, response)
            resolve(response);
          }).catch((error) => {
            const errorObject = JSON.parse(error.message)
            context.commit('notification/error', errorObject.message, { root: true })
            reject(error);
          })
        })
    },

    [DELETE_SUBSCRIPTION](context, order_ref) {
      return new Promise((resolve, reject) => {
        const iamportKey = context.rootState.auth.currentUser.iamportKey;
        SubscriptionService.deleteSubscription(order_ref, iamportKey).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },

    [FETCH_ALL_PRICE](context) {
      return new Promise((resolve, reject) => {
        const merchantUuid = context.rootState.auth.currentUser.merchantUuid;
        const environment = context.rootState.auth.environment;
        SubscriptionService.fetchAllPrice(merchantUuid, environment).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },

    [FETCH_SUBSCRIPTION_DETAILS](context, {order_ref, key}) {
      return new Promise((resolve, reject) => {
        const iamportKey = context.rootState.auth.currentUser.is_master_merchant ? key : context.rootState.auth.currentUser.iamportKey;
        SubscriptionService.fetchSubscription(order_ref, iamportKey).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },
    [FETCH_SUBSCRIPTION_TRANSACTIONS](context, {order_ref, key}) {
      return new Promise((resolve, reject) => {
        const merchantUuid = context.rootState.auth.currentUser.merchantUuid;
        const environment = context.rootState.auth.environment;
        const iamportKey = context.rootState.auth.currentUser.is_master_merchant ? key : context.rootState.auth.currentUser.iamportKey;
        const payload = {
          "uuid": merchantUuid,
          "environment": environment
      }
        SubscriptionService.fetchSubscriptionTransactions(order_ref, payload, iamportKey).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },
    [POST_COLLECT_PAYMENT](context, payload) {
      return new Promise((resolve, reject) => {
        payload = {
          ...payload,
          "chaipay_key": context.rootState.auth.currentUser.iamportKey,
          "environment": context.rootState.auth.environment,
        }
        SubscriptionService.postCollectPayment(payload).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },
}

export const namespaced = true;