import Headers from '../headers'

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const ReconAnalyticsService = {
  getReconSummary,
  getReconSummaryTableRecords,
  downloadReconSummaryTableRecords,
  downloadReconSummary
};

function getReconSummary(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getReconSummaryTableRecords(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/dashboard-records`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function downloadReconSummaryTableRecords(iamportKey, payload) {

  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/export-dashboard-records`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `recon_dashboard_${payload.record_type}_` + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function downloadReconSummary(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(payload)
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/export-recon-summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "Reconciliation summary" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}