import { NotificationService } from '@/helpers/backend/notifications.service';
import {
    FETCH_NOTIFICATIONS,
    MARK_NOTIFICATION_READ,
    READ_ALL_NOTIFICATIONS
} from '../actions.type';

import {
    ADD_NOTIFICATIONS_IN_STORE,
    CLEAR_NOTIFICATION_STATE
  } from '../mutations.type';

export const state = {
    content: [],
    page: 1,
    size: 10,
    totalElements: 0,
    notificationsPageContent: []
};

export const mutations = {
    [ADD_NOTIFICATIONS_IN_STORE](state, {notifications, isNotificationPage}) {
        if(isNotificationPage) {
            state.notificationsPageContent = [...state.notificationsPageContent, ...notifications.content];
        } else {
            state.content = [...state.content, ...notifications.content];
            state.page = notifications.page;
            state.size = notifications.size;
            state.totalElements = notifications.total_elements;
        }
    },
    [CLEAR_NOTIFICATION_STATE](state, {notificationsPage, reset}) {
        if(notificationsPage && reset) {
            state.notificationsPageContent = []
        }
        state.content = [];
        // state.page = 1;
        // state.size = 0;
        state.totalElements = 0;
    }
}

export const getters = {
    moreNotificationsAvailable(state) {
        return state.totalElements > state.content.length;
    },
    moreNotifications(state) {
        return state.totalElements > state.notificationsPageContent.length;
    },
}

export const actions = {

    [FETCH_NOTIFICATIONS](context, data) {
        if((!data.showNotifications && !data.notificationsPage) || data.reset) {
            context.commit(CLEAR_NOTIFICATION_STATE, { notificationsPage: data.notificationsPage, reset: data.reset })
        }
        return new Promise((resolve, reject) => {
            const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
            const isNotificationPage = data.notificationsPage;
            
            const payload = { ...data, environment: context.rootState.auth.environment };
            
            delete payload.showNotifications;
            delete payload.reset;
            delete payload.notificationsPage;
            NotificationService.fetchNotifications(merchantUUID, payload).then((response) => {
                if(response.content) {
                    context.commit(ADD_NOTIFICATIONS_IN_STORE, { notifications: response, isNotificationPage })
                }
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            });
        });
    },

    [MARK_NOTIFICATION_READ](context, notificationUUID) {
        return new Promise((resolve, reject) => {
            const merchantUUID = context.rootState.auth.currentUser.merchantUuid;

            NotificationService.readNotification(merchantUUID, notificationUUID).then((response) => {
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        });
    },

    [READ_ALL_NOTIFICATIONS](context) {
        return new Promise((resolve, reject) => {
            const merchantUUID = context.rootState.auth.currentUser.merchantUuid;

            NotificationService.readAllNotifications(merchantUUID).then((response) => {
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                context.commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        });
    },
}