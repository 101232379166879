import { PaymentPageService } from '../../helpers/backend/payment_pages.service';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

import {
  FETCH_PAYMENT_PAGE_DETAILS,
  FETCH_PAYMENT_PAGE_LIST,
  FETCH_PAYMENT_PAGE_TRANSACTIONS,
  EXPORT_PAYMENT_PAGE_TRANSACTIONS,
  CANCEL_PAYMENT_PAGE,
  EXPORT_PAYMENT_PAGE_LIST,
  CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY,
  GENERATE_QR_CODE
} from '../actions.type';

import {
  ADD_PAYMENT_PAGE_RESULTS_IN_STORE,
  CREATE_PAYMENT_PAGE_RECORD_IN_STORE,
  ADD_PAYMENT_PAGE_DETAIL_IN_STATE,
  UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE,
  UPDATE_PAYMENT_PAGE_TRANSACTIONS_IN_STATE,
  UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE,
  UPLOAD_PAGE_IMAGE_IN_STORE,
  ADD_PREVIEW_PAYMENT_PAGE_DETAIL_IN_STATE
} from '../mutations.type';

export const state = {
  isFetchingData: false,
  pageDetails: {},
  previewPageDetails: {},
  content: [],
  pageTransactions: [],
  total_elements: 0,
  total_pages: 0,
  size: 0,
  page: 0,
  number_of_elements: 0,
}

export const mutations = {
  [ADD_PAYMENT_PAGE_RESULTS_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.content = newTransactionData.content;
    state.total_elements = newTransactionData.total_elements;
    state.total_pages = newTransactionData.total_pages;
    state.size = newTransactionData.size;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.number_of_elements;
  },
  [UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE](state, isFetching) {
    state.isFetchingData = isFetching;
  },
  [ADD_PAYMENT_PAGE_DETAIL_IN_STATE](state, pageDetails) {
    state.pageDetails = pageDetails;
  },
  [ADD_PREVIEW_PAYMENT_PAGE_DETAIL_IN_STATE](state, pageDetails) {
    state.previewPageDetails = pageDetails;
  },
  [UPDATE_PAYMENT_PAGE_TRANSACTIONS_IN_STATE](state, transactions) {
    if(!transactions.transactions.content) {
      transactions.transactions.content = [];
    }
    state.pageTransactions = transactions;
  }
}

export const getters = {
}

export const actions = {
  [FETCH_PAYMENT_PAGE_LIST]({ commit, rootState }, pageData) {
    commit(UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const isMasterMerchant = rootState.auth.currentUser.is_master_merchant;
      pageData.environment = rootState.auth.environment;

      if(!pageData.from) {
        pageData.from = "1991-01-01 15:04:05";
      }

      PaymentPageService.fetchPaymentPageResults(iamportKey, pageData, isMasterMerchant).then((response) => {
        const paylinks_content = response.content;

        const paymentLinksData = {
          content: paylinks_content,
          total_elements: response.total_elements,
          total_pages: response.total_pages,
          size: response.size,
          page: response.page,
          number_of_elements: response.number_of_elements,
        }
        commit(ADD_PAYMENT_PAGE_RESULTS_IN_STORE, paymentLinksData)
        commit(UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE, false)
        resolve(paymentLinksData);
      }).catch((error) => {
        commit(UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [CREATE_PAYMENT_PAGE_RECORD_IN_STORE]({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {
      const currentUser = rootState.auth.currentUser,
        merchantOrderId = 'MERCHANT' + new Date().getTime(),
        environment = rootState.auth.environment;

      const { iamportKey, iamportSecret, merchantUuid } = currentUser;

      let mainParams = "amount="+encodeURIComponent(formData.amount)+"&client_key="+encodeURIComponent(iamportKey)+"&currency="+encodeURIComponent("VND")+"&failure_url="+encodeURIComponent(formData.failure_url)+"&merchant_order_id="+encodeURIComponent(merchantOrderId)+"&success_url="+encodeURIComponent(formData.success_url)

      let hash = hmacSHA256(mainParams, iamportSecret);

      let signatureHash = Base64.stringify(hash);

      formData.merchant_details.name = currentUser.brand_name;
      formData.merchant_details.promo_discount = 0.0;
      formData.merchant_details.shipping_charges = 0.0;

      const data = {
        ...formData,
        "chaipay_key": iamportKey,
        "merchant_order_id": merchantOrderId,
        "signature_hash": signatureHash,
        "source":"default",
        "environment":environment
      };

      PaymentPageService.createPaymentPage(merchantUuid, iamportKey, data).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },

  [FETCH_PAYMENT_PAGE_DETAILS]({ commit, rootState }, { pageRef, submerchantKey, isPreviewPage = false }) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchantKey : rootState.auth.currentUser.iamportKey;
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      PaymentPageService.fetchPaymentPageDetails(merchantUUID, iamportKey, pageRef).then((response) => {
        if(isPreviewPage) {
          commit(ADD_PREVIEW_PAYMENT_PAGE_DETAIL_IN_STATE, response)
        } else {
          commit(ADD_PAYMENT_PAGE_DETAIL_IN_STATE, response)
        }
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYMENT_PAGE_TRANSACTIONS]({ commit, rootState }, {payload, pageRef}) {
    commit(UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE, true);
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;

      payload.environment = rootState.auth.environment;
      payload.uuid = rootState.auth.currentUser.merchantUuid;

      PaymentPageService.fetchPaymentPageTransactions(iamportKey, payload, pageRef).then((response) => {
        commit(UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE, false)
        commit(UPDATE_PAYMENT_PAGE_TRANSACTIONS_IN_STATE, response)
        resolve(response);
      }).catch((error) => {
        commit(UPDATE_PAYMENT_PAGE_RESULTS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE]({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {

      const environment = rootState.auth.environment;
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const merchantUUID = rootState.auth.currentUser.merchantUuid;

      const data = {
        ...formData,
        "key": iamportKey,
        "environment": environment
      };

      PaymentPageService.updatePaymentPage(merchantUUID, iamportKey, data, formData.page_ref).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },

  [UPLOAD_PAGE_IMAGE_IN_STORE]({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {

      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      const iamportKey = rootState.auth.currentUser.iamportKey;

      PaymentPageService.uploadPaymentPageImage(merchantUUID, iamportKey, formData).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },

  [EXPORT_PAYMENT_PAGE_TRANSACTIONS]({commit, rootState}, {pageData, pageRef}) {
    return new Promise((resolve, reject) => {

      const iamportKey = rootState.auth.currentUser.iamportKey;
      pageData.environment = rootState.auth.environment;

      PaymentPageService.exportPaymentPageTransactions(iamportKey, pageData, pageRef).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },
  [CANCEL_PAYMENT_PAGE]({commit, rootState}, pageRef) {
    return new Promise((resolve, reject) => {
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      const iamportKey = rootState.auth.currentUser.iamportKey;

      PaymentPageService.cancelPaymentPage(merchantUUID, iamportKey, pageRef).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },
  [EXPORT_PAYMENT_PAGE_LIST]({commit, rootState}, data) {
    return new Promise((resolve, reject) => {

      const iamportKey = rootState.auth.currentUser.iamportKey;
      const isMasterMerchant = rootState.auth.currentUser.is_master_merchant;

      if(!data.from) {
        data.from = "1991-01-01 15:04:05";
      }
      data.environment = rootState.auth.environment;

      PaymentPageService.exportPaymentPageList(iamportKey, data, isMasterMerchant).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },
  [CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY]({commit, rootState}, pageRef) {
    return new Promise((resolve, reject) => {
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      const iamportKey = rootState.auth.currentUser.iamportKey;

      PaymentPageService.checkCustomUrlAvailablity(merchantUUID, iamportKey, pageRef).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },

  [GENERATE_QR_CODE]({ commit, rootState }, { pageRef }) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      PaymentPageService.generateQRCode(iamportKey, pageRef).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
}

export const namespaced = true;
