import { ReserveLedgerService } from '../../helpers/backend/reserve_ledger.service';
import * as cloneDeep from "lodash.clonedeep";
import moment from 'moment';

import {
  FETCH_LEDGER,
  DOWNLOAD_VA_EXCEL_LEDGER

} from '../actions.type';
import {
  UPDATE_LEDGER_DATA_IN_STORE,
  TOGGLE_LEDGER_LOADER_IN_STORE
} from '../mutations.type';
export const state = {
  isFetchingData: false,
  ledgerData: [],
  total_elements: 0,
  total_pages: 0,
  size: 10,
  page: 1,
  number_of_elements: 0
}

export const mutations = {
  [TOGGLE_LEDGER_LOADER_IN_STORE](state, toggle) {
    state.isFetchingData = toggle;
  },
  [UPDATE_LEDGER_DATA_IN_STORE](state, accountStatement) {
    state.ledgerData = cloneDeep(accountStatement.content);
    state.total_elements = accountStatement.total_elements;
  },
}

export const getters = {
  getAccountList(state) {
    return state.ledgerData;
  }
}
export const actions = {

  [FETCH_LEDGER](context, data) {
    return new Promise((resolve, reject) => {
      const iamportKey = context.rootState.auth.currentUser.iamportKey;
			const environment = context.rootState.auth.environment;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
    context.commit(TOGGLE_LEDGER_LOADER_IN_STORE, true);
    ReserveLedgerService.fetchLedger(merchantUUID, { iamportKey,environment,data }).then((response) => {
        context.commit(TOGGLE_LEDGER_LOADER_IN_STORE, false);
        if (response.content === null) response.content = [];
        const statementContent = response.content.map((statement) => {
          statement.date = moment(statement.created_at).format('MMMM Do YYYY, h:mm:ss a');
          return statement
        })
        response.content = statementContent;
        context.commit(UPDATE_LEDGER_DATA_IN_STORE, response);
        resolve(response);
      }).catch((error) => {
        context.commit(TOGGLE_LEDGER_LOADER_IN_STORE, false);
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [DOWNLOAD_VA_EXCEL_LEDGER](context, data) {
    return new Promise((resolve, reject) => {
      const iamportKey = context.rootState.auth.currentUser.iamportKey;
			const environment = context.rootState.auth.environment;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      ReserveLedgerService.downloadExcelLedger(merchantUUID, { iamportKey,environment,data }).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
}

export const namespaced = true;