export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const OnboardingFormService = {
  fetchonboardingformdata,
  addonboardingformdata,
  
};

// function fetchformdata() {
//     const requestOptions = {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' },
//         credentials: 'include',
//         };
//         //const queryParams = new URLSearchParams({environment});
//         return fetch(`${CHAIPAY_API_URL}/onboarding/merchants/cadfc143-a968-41ed-b408-163cb5dffc44/GBPRIMEPAY?environment=sandbox`, requestOptions).then(async response => {
//             const data = await response.json();
            
//             if (!response.ok) {
//                 // get error message from body or default to response statusText
//                 const error = (data && data.message) || response.statusText;
//                 this.errorMessage = error;
//                 return Promise.reject(error);
//             }
            
//             //debugger;
//             this.formdata = data.formData.map( val =>{
//                 console.log(typeof data.initialValues[val.fieldName]);

//                 val.value = data.initialValues[val.fieldName] == undefined ? "" : data.initialValues[val.fieldName];
//                 val.disabled = data.initialValues[val.fieldName] == undefined ? false : true;
//                 return val

//             }) ;
//             })
//             .catch(error => {
//             this.errorMessage = error;
//             console.error("There was an error!", error);
//             });
            
  
// }


function fetchonboardingformdata(merchantUUID, environment, channelName) {
    var queryParams = new URLSearchParams(window.location.search)
    channelName = queryParams.get("channelName")
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/onboarding/merchants/${merchantUUID}/${channelName}?environment=${environment}`, requestOptions).then((response) => {
        if (response.ok) {
            return response.json();
          } else {
            return response.text().then(text => {
              if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
                window.location = window.location.origin + '/logout';
              }
              throw new Error(text);
            });
          }
        });
  }


  function addonboardingformdata(merchantUUID, environment, reqBody) {

    // channel details vary from channel to channel
    var queryParams = new URLSearchParams(window.location.search)
    var channelName = queryParams.get("channelName")
    const requestOptions = {
      method: 'POST',
      headers: { 
        "Accept": "application/json",
       },
      body: reqBody,
      credentials: 'include',
    };
  
    return fetch(`${CHAIPAY_API_URL}/onboarding/merchants/${merchantUUID}/${channelName}?environment=${environment}`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
  }
  
  


