import { RemittanceSummaryService } from '../../helpers/backend/remittance_summary.service';
import moment from 'moment';
import {
  FETCH_REMITTANCE_SUMMARY,
  EXPORT_REMITTANCE_SUMMARY_INTO_EXCEL,
} from '../actions.type';
import {
	ADD_REMITTANCE_SUMMARY_IN_STORE,
	UPDATE_REMITTANCE_SUMMARY_FETCHING_STATE
} from '../mutations.type';

export const state = {
	isFetchingData: false,
	content: [],
	total_elements: 0,
	total_pages: 0,
	size: 0,
	page: 0,
	number_of_elements: 0,
}

export const mutations = {

	[ADD_REMITTANCE_SUMMARY_IN_STORE](state, newTransactionData) {
		state.isFetchingData = newTransactionData.isFetchingData;
		state.content = newTransactionData.content;
		state.total_elements = newTransactionData.total_elements;
		state.total_pages = newTransactionData.total_pages;
		state.size = newTransactionData.size;
		state.page = newTransactionData.page;
		state.number_of_elements = newTransactionData.number_of_elements;
	},
	[UPDATE_REMITTANCE_SUMMARY_FETCHING_STATE](state, isFetching) {
		state.isFetchingData = isFetching;
	},
}

export const getters = {

}

export const actions = {

	[FETCH_REMITTANCE_SUMMARY]({ commit, rootState }, { pageIndex, pagesize, filters, toDate, fromDate }) {
		commit(UPDATE_REMITTANCE_SUMMARY_FETCHING_STATE, true)
		return new Promise((resolve, reject) => {
			const iamportKey = rootState.auth.currentUser.iamportKey;
			const environment = rootState.auth.environment;
			const merchantUUId = rootState.auth.currentUser.merchantUuid;
			RemittanceSummaryService.getremittanceSummaryList(merchantUUId, iamportKey, pageIndex, pagesize, filters, toDate, fromDate, environment).then((response) => {
				if(response.content === null) {
					response.content = [];
				}
				const trxn_content = response.content.map((trxn) => {
					return {
						status: trxn.status,
						date: moment(trxn.created_at).format('MMMM Do YYYY, h:mm:ss a'),
						destination_currency: trxn.destination_currency,
						source_currency: trxn.source_currency,
						total_txn_amount:(trxn.total_txn_amount_sc).toLocaleString('en-US', { style: 'currency', currency: trxn.source_currency }),
						txn_fee: (trxn.txn_fee_sc).toLocaleString('en-US', { style: 'currency', currency: trxn.source_currency }),
						with_hodling_tax: (trxn.withholding_tax_sc).toLocaleString('en-US', { style: 'currency', currency: trxn.source_currency }),
						refund_amount_sc: (trxn.refund_amount_sc).toLocaleString('en-US', { style: 'currency', currency: trxn.source_currency }),
						reserve_credit_bc: (trxn.reserve_credit_bc).toLocaleString('en-US', { style: 'currency', currency: trxn.source_currency }),
						reserve_debit_bc: (trxn.reserve_debit_bc).toLocaleString('en-US', { style: 'currency', currency: trxn.source_currency }),
						transfer_fee: (trxn.transfer_fee_bc).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
						applied_forex_rate: trxn.applied_forex_rate,
						net_amount_transferred: trxn.destination_currency ? (trxn.net_amount_transferred_dc).toLocaleString('en-US', { style: 'currency', currency: trxn.destination_currency }) : trxn.net_amount_transferred_dc
					};
				});
				const transactionData = {
					content: trxn_content,
					total_elements: response.total_elements,
					total_pages: response.total_pages,
					size: response.size,
					page: response.page,
					number_of_elements: response.number_of_elements,
				}
				commit(ADD_REMITTANCE_SUMMARY_IN_STORE, transactionData)
				commit(UPDATE_REMITTANCE_SUMMARY_FETCHING_STATE, false)
				resolve(transactionData);
			}).catch((error) => {
				commit(UPDATE_REMITTANCE_SUMMARY_FETCHING_STATE, false)
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},

	[EXPORT_REMITTANCE_SUMMARY_INTO_EXCEL]({ commit, rootState }, { pageIndex, pagesize, filters, toDate, fromDate }) {
		return new Promise((resolve, reject) => {
		const iamportKey = rootState.auth.currentUser.iamportKey;
		const environment = rootState.auth.environment;
		const merchantUUId = rootState.auth.currentUser.merchantUuid;
		RemittanceSummaryService.exportremittanceSummary(merchantUUId, iamportKey, pageIndex, pagesize, filters, toDate, fromDate, environment).then((response) => {
			resolve(response);
		}).catch((error) => {
			const errorObject = JSON.parse(error.message)
			commit('notification/error', errorObject.Message, { root: true })
			reject(error);
		})
		})
	}
}

export const namespaced = true;
