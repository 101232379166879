import Headers from '../headers';
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const DashboardRemittancesService = {
  fetchRemittancesTransactionsStats,
  // fetchTransactionVolume,
  // fetchTransactionSeasonality,
  fetchRemittancesTransactionVolForAllPieCharts,
  fetchRemittancesTransactionStackedBar
};

function fetchRemittancesTransactionsStats(merchantUUId, filters, from_date, to_date, isMasterMerchant, merchant_uuids, environment, currency) {
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filters,"uuids":uuidsToSend, currency,"from":from_date,"to":to_date,"is_base_currency": true}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/cross-border/dashboard/transaction-metrics/${merchantUUId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchRemittancesTransactionVolForAllPieCharts(merchantUUId, filters, from_date, to_date, isMasterMerchant, merchant_uuids, environment, currency) {
  // const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filters,"uuids":uuidsToSend, currency,"from":from_date,"to":to_date,"groupby":"payment_methods","is_base_currency": true}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/cross-border/dashboard/pie-charts/${merchantUUId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

// function fetchTransactionVolume(merchantUUId, filter, isMasterMerchant, merchant_uuids, environment, currency) {
//   const offset = new Date().getTimezoneOffset();
//   if(isMasterMerchant){
//     if(merchant_uuids.length > 0){
//       var uuidsToSend = merchant_uuids.map(function (item) {
//         return item.uuid;
//       });
//     }else{
//       uuidsToSend = [];
//     }
//   }else{
//     uuidsToSend = [merchantUUId]
//   }
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({environment,filter,offset,"uuids":uuidsToSend, currency}),
//     credentials: 'include',
//   };
//   return fetch(`${CHAIPAY_API_URL}/api/analytics/txn-volume-graph/${merchantUUId}`, requestOptions).then((response) => {
//     if (response.ok) {
//       return response.json();
//     } else {
//       return response.text().then(text => {
//         throw new Error(text);
//       });
//     }
//   });
// }

// function fetchTransactionSeasonality(merchantUUId, filter, isMasterMerchant, merchant_uuids, environment, currency) {
//   const offset = new Date().getTimezoneOffset();
//   if(isMasterMerchant){
//     if(merchant_uuids.length > 0){
//       var uuidsToSend = merchant_uuids.map(function (item) {
//         return item.uuid;
//       });
//     }else{
//       uuidsToSend = [];
//     }
//   }else{
//     uuidsToSend = [merchantUUId]
//   }
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({environment,filter,offset,"uuids":uuidsToSend, currency}),
//     credentials: 'include',
//   };
//   return fetch(`${CHAIPAY_API_URL}/api/analytics/txn-volume-seasonality-graph/${merchantUUId}`, requestOptions).then((response) => {
//     if (response.ok) {
//       return response.json();
//     } else {
//       return response.text().then(text => {
//         throw new Error(text);
//       });
//     }
//   });
// }

function fetchRemittancesTransactionStackedBar(merchantUUId, filters, from_date, to_date, isMasterMerchant, merchant_uuids, environment, currency) {
  // const offset = new Date().getTimezoneOffset();
  const offset = 0;
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filters,offset,"uuids":uuidsToSend, currency,"from":from_date,"to":to_date,"is_base_currency": true}),
    credentials: 'include',
  }
  return fetch(`${CHAIPAY_API_URL}/api/cross-border/dashboard/txn-stacked/${merchantUUId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
