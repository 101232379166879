import Headers from '../headers'

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const DashboardService = {
  fetchTransactionsStats,
  fetchLates_txn,
  fetchTransactionVolume,
  fetchTransactionSeasonality, 
  fetchTransactionVolForAllPieCharts,
  fetchTransactionStackedBar,
  fetchTransactionsStatus,
  fetchTransactionVolForAllPieChartsMetrics,
  fetchTransactionsStatistics
};

function fetchTransactionsStats(merchantUUId, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }

  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,offset,"uuids":uuidsToSend, currency, "time_filter": {"to": to_date,"from": from_date}}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUId}/analytics/net-transactions-data`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      })
    }
  });
}

function fetchLates_txn(merchantUUId, isMasterMerchant, merchant_uuids, environment) {
  // const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = []
  }

  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,"uuids":uuidsToSend,}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUId}/analytics/latest-txns`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionsStatistics(merchantUUId, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date, time_bracket) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }

  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,offset,"uuids":uuidsToSend, currency,"time_bracket": time_bracket, "time_filter": {"to": to_date,"from": from_date}}),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUId}/analytics/txn-bar-chart-metrics`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionsStatus(merchantUUId, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,offset,"uuids":uuidsToSend, currency,"time_filter": {"to": to_date,"from": from_date}}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUId}/analytics/txn-status-metrics`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionVolForAllPieCharts(merchantUUId, filter, aggtype, isMasterMerchant, merchant_uuids, environment, currency) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filter,aggtype,offset,"uuids":uuidsToSend, currency}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/analytics/pie-charts/${merchantUUId}?`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionVolForAllPieChartsMetrics(merchantUUId, isMasterMerchant, merchant_uuids, environment, currency, from_date, to_date) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,offset,"uuids":uuidsToSend, currency ,  "time_filter": {"to": to_date,"from": from_date}}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUId}/analytics/pie-charts-metrics?`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionVolume(merchantUUId, filter, isMasterMerchant, merchant_uuids, environment, currency) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filter,offset,"uuids":uuidsToSend, currency}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/analytics/txn-volume-graph/${merchantUUId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionSeasonality(merchantUUId, filter, isMasterMerchant, merchant_uuids, environment, currency) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filter,offset,"uuids":uuidsToSend, currency}),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/api/analytics/txn-volume-seasonality-graph/${merchantUUId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchTransactionStackedBar(merchantUUId, filter, isMasterMerchant, merchant_uuids, environment, currency) {
  const offset = new Date().getTimezoneOffset();
  if(isMasterMerchant){
    if(merchant_uuids.length > 0){
      var uuidsToSend = merchant_uuids.map(function (item) {
        return item.uuid;
      });
    }else{
      uuidsToSend = [];
    }
  }else{
    uuidsToSend = [merchantUUId]
  }
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify({environment,filter,offset,"uuids":uuidsToSend, currency}),
    credentials: 'include',
  }
  return fetch(`${CHAIPAY_API_URL}/api/analytics/txn-volume-status-stacked/${merchantUUId}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}
