export const LOGIN = "logInToChai";
export const LOGOUT = "logOut";
export const REGISTER = "register";
export const SET_USER_LANGUAGE = "setUserLanguage";
export const SEND_RESET_PASSWORD_LINK = "sendResetPasswordLink";
export const SEND_EMAIL_VERIFICATION_LINK = "sendEmailVerificationLink";
export const RESET_PASSWORD = "resetPassword";
export const VALIDATE = "validate";
export const FETCH_WEBHOOKS = "fetchWebhooksData";
export const ADD_WEBHOOK = "addWebhookData";
export const UPDATE_WEBHOOK = "updateWebhookData";
export const UPDATE_WEBHOOK_BY_FORM = "updateWebhookByFormData"
export const DELETE_WEBHOOK = "deleteWebhookData";
export const UPDATE_SECRET_KEY_BY_FORM = "updateSecretKeyByFormData";
export const SET_SANDBOX_MODE_ALERT = "setSandboxModeAlert";
export const SET_UPDATE_ALERT = 'setUpdateAlert';

export const SET_TOPBAR_HEIGHT = "setTopBarHeight";

export const UPDATE_MERCHANT_ENTITY_DETAILS = "updateMerchantEntityDetails";
export const UPDATE_MERCHANT_LOGO = "updateMerchantLogo";
export const FETCH_MERCHANT_ENTITY_DETAILS = "fetchMerchantEntityDetails";
export const FETCH_TRANSACTIONS = "fetchTransactionsData";
export const FETCH_CHANNEL_LIST = "fetchChannelListData";
export const EXPORT_TRANSACTIONS_INTO_EXCEL = "exportTransactionsIntoExcel";
export const FETCH_PAYMENT_CHANNEL_CREDENTIALS = "fetchPaymentChannelCredentials";
export const FETCH_PAYOUT_CHANNEL_CREDENTIALS = "fetchPayoutChannelCredentials";
export const UPDATE_PAYMENT_CHANNEL_CREDENTIALS = "updatePaymentChannelCredentials";
export const TOGGLE_PAYOUTS_LOADER = 'toggelPayoutsLoader';
export const UPDATE_PAYOUT_CHANNEL_CREDENTIALS = "updatePayoutChannelCredentials";
export const UPDATE_PAYOUT_STATUS = "updatePayoutStatus";
export const FETCH_COLUMN_LIST = "fetchColumnList";
export const UPDATE_COLUMN_LIST = "updateColumnList";
export const RESET_COLUMN_LIST = "resetColumnList";

export const FETCH_TRANSACTION_STATUS = "fetchTransactionStatus";
export const FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS_METRICS = "fetchTransactionDataForDoughnutChartsMetrics";
export const FETCH_TRANSACTION_STATS = "fetchTransactionStats";
export const FETCH_LATEST_TXNS = "fetchLatestTxns";
export const FETCH_TRANSACTION_STATISTICS = "fetchTransactionStatistics";
export const FETCH_TRANSACTION_VOLUME = "fetchTransactionVolume";
export const FETCH_TRANSACTION_VOLUME_SEASONALITY = "fetchTransactionSeasonality";
export const FETCH_TRANSACTION_VOLUME_STACKED_BAR = "fetchTransactionStackedBar";
export const FETCH_TRANSACTION_VOLUME_BY_STATUS = "fetchTransactionVolByStatus";
export const FETCH_TRANSACTION_VOLUME_BY_CHANNEL = "fetchTransactionVolByPmtChannel";
export const FETCH_TRANSACTION_VOLUME_BY_METHOD = "fetchTransactionVolByPmtMethod";
export const FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS = "fetchTransactionDataForDoughnutCharts";
export const SET_SELECTED_TRANSACTION_ID = "setSelectedTransactionID";
export const FETCH_WEBHOOK_DATA_FOR_TRANSACTION = "fetchWebhookDataForTransaction";
export const FETCH_FAILOVER_DATA_FOR_TRANSACTION = "fetchFailoverDataForTransaction";
export const RESEND_WEBHOOK_DATA_FOR_TRANSACTION = "resendWebhookDataForTransaction";
export const CREATE_REFUND_FOR_TRANSACTION = "createRefundForTransaction";
export const FETCH_REFUND_DATA_FOR_TRANSACTION = "fetchRefundDetailsForTransaction";
export const FETCH_BANKS_LIST = "fetchBanksList";
export const FETCH_TRANSACTION_DETAILS = "fetchTransactionDetails";
export const UPDATE_TRXN_STATUS = "updateTransactionStatus";
export const UPDATE_REFUND_STATUS = "updateRefundStatus";
export const TRANSACTION_FILE_UPLOAD = "transactionFileUpload";


export const FETCH_PAYMENT_LINKS = "fetchPaymentLinks";
export const POST_PAYMENT_LINKS = "addPaymentLinks";
export const DELETE_PAYMENT_LINKS = "deletePaymentLinks";
export const NOTIFICATION_PAYMENT_LINKS = "notificationPaymentLinks";
export const FETCH_PAYMENT_LINK_DETAILS = "fetchPaymentLinkDetails";
export const FETCH_PAYMENT_LINK_TRANSACTIONS = "fetchPaymentLinkTransactions";
export const CHECK_REF_LINK_PAYMENT_LINKS = "checkRefLinkPaymentLinks";
export const EXPORT_PAYMENT_LINK_LIST = "exportPaymentLinkList";
export const FETCH_SOURCE_LIST = "fetchSourceList";


//Payouts
export const FETCH_VIRTUAL_ACCOUNTS = "fetchVirtualAccounts";
export const FETCH_VIRTUAL_ACCOUNTS_MASTER_MERCHANT = "fetchVirtualAccountsMasterMerchant";
export const CREATE_VIRTUAL_ACCOUNT = "createVirtualAccount";
export const FETCH_PAYOUT_LIST = "fetchPayoutLists";
export const FETCH_PAYOUT_LIST_MASTER_MERCHANT = "fetchPayoutListMasterMerchant";
export const FETCH_ACCOUNT_STATEMENT = "fetchAccountStatement";
export const CREATE_PAYOUTS = "createPayouts";
export const UPDATE_PAYOUTS = "updatePayouts";
export const APPROVE_PAYOUTS = "approvePayouts";
export const APPROVE_PAYOUTS_VIA_EMAIL = "approvePayoutsViaEmail";
export const VERIFY_PAYOUTS_VIA_EMAIL = "verifyPayoutsViaEmail";
export const FETCH_BENEFICIARY_TABLE = "fetchBeneficiaryTable";
export const FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT = "fetchBeneficiaryTableMasterMerchant";
export const CREATE_BENEFICIARY = "createBeneficiary";
export const UPDATE_BENEFICIARY = "updateBeneficiary";
export const UPDATE_BENEFICIARY_ACCOUNT = "updateBeneficiaryAccount";
export const GENERATE_OTP_PAYOUT = "generateOtpPayout";
export const GENERATE_OTP_PAYOUT_VIA_EMAIL = "generateOtpPayoutViaEmail";
export const GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY = "generateOtpPayoutViaEmailForVerify";
export const RESEND_OTP_PAYOUT_VIA_EMAIL = "resendOtpPayoutViaEmail";
export const RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY = "resendOtpPayoutViaEmailForVerify";
export const DOWNLOAD_VA_EXCEL_STATEMENT = "downloadExcelStatement";
export const PAYOUT_FILE_UPLOAD = "payoutFileUpload";
export const EXPORT_PAYOUTS_INTO_EXCEL = "exportPayoutsIntoExcel";
export const FETCH_PAYOUT_DETAILS = "fetchPayoutDetails";
export const FETCH_BENEFICIARY_DETAILS = "fetchBeneficiaryDetails";

// User management
export const CREATE_SUB_USER = "createSubUser"
export const UPDATE_SUB_USER_DETAILS = "updateSubUserDetails"
export const FETCH_DETAILS_FOR_ALL_SUB_USERS = "fetchDetailsForAllSubUsers"
export const FETCH_SUB_USER_DETAILS_BY_ID = "fetchSubUserDetailsById"
export const VERIFY_SUB_USER_PERMISSIONS = "verifySubUserPermissions"

// 2FA
export const SETUP_OR_GENERATE_2FA_OTP = "setupOrGenerate2FA"
export const VERIFY_2FA_FOR_CURRENT_USER = "verify2FAForCurrentUser"

// payment channels
export const FETCH_ALL_PAYMENT_CHANNEL = "fetchAllPaymentChannel";
export const FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS = "fetchSelectedPaymentChannelCredentials";
export const FETCH_PAYMENT_METHODS_CREDENTIALS = "fetchPaymentMethodsCredentials";
export const FETCH_PAYMENT_METHODS_CREDENTIALS_COUNT = "fetchPaymentMethodsCredentialsCount";
export const UPDATE_PAYMENT_METHOD_CREDENTIALS = "updatePaymentMethodCredentials";
export const ADD_PAYMENT_METHOD_CREDENTIALS = "addPaymentMethodCredentials";
export const UPDATE_ENABLE_AND_DEFAULT_STATUS = "updateEnableAndDefaultStatus";
export const FETCH_ALL_PAYMENT_PAYOUT_CHANNEL = "fetchAllPaymentPayoutChannel";
export const FETCH_ALL_PAYMENT_CHANNEL_FOR_PREVIEW = "fetchAllPaymentChannelForPreview";
export const FETCH_PAYMENT_ONBOARDING = "fetchPaymentOnboarding";
export const ONBOARDING_STATUS_CHECK = "onboardingStatusCheck";
export const FETCH_ONBOARDING_FORM_DATA = "fetchonboardingformdata";
export const ADD_ONBOARDING_FORM_DATA = "addonboardingformdata";
export const UPDATE_INSTALMENT_TYPE_DATA = "updateInstallmentTypeData";
export const ADD_PAYMENT_METHOD_IMPORT_CREDENTIALS = "addPaymentMethodImportCredentials";
export const FETCH_PREVIEW_IAMPORT_CREDENTIALS = "fetchPreviewIamportCredentials"

// Recon
export const UPDATE_FILE_UPLOAD = "updateFileUpload";
export const CREATE_RECON = "createRecon";
export const FETCH_RECONCILIATION_RESULTS = "fetchReconciliationResults";
export const FETCH_INGESTION_SUMMARY = "fetchIngestionSummary";
export const FETCH_RECONCILIATIONS = "fetchReconciliations";
export const FETCH_RECONCILIATION_SUMMARY_RESULTS = "fetchReconciliationSummaryResults";
export const FETCH_RECONCILIATION_SUMMARY_FOR_PSP_RESULTS = "fetchReconciliationSummaryForPspResults";
export const FETCH_PSP_RESULTS_FOR_RECONCILIATION_SUMMARY = "fetchPspResultsForReconciliationSummary";
export const RECON_DEATILS = "reconDetails";
export const RECON_TRANSACTIONS = "reconTransactions";
export const RECON_SETTLEMENTS = "reconSettlements";
export const FETCH_CHANNEL_LIST_FOR_RECON = "fetchChannelListForRecon";
export const MARK_RECONCILED_IN_STORE = "markReconciledInStore";
export const CLEAR_RECON_DEATILS = "clearReconDetails";
export const EXPORT_MISMATCH_TRANSACTIONS_INTO_CSV = "exportMismatchTransactionsIntoCSV";
export const EXPORT_RECON_SUMMERY_INTO_CSV = "exportReconSummeryIntoCSV";
export const FETCH_USER_DATA_BY_PROPERTY_TYPE = "fetchUserDataByPropertyType";
export const FETCH_USER_DATA_BY_PROPERTY_TYPE_LIST = "fetchUserDataByPropertyTypeList";
export const FETCH_RECON_TRANSACTIONS = "fetchReconTransactionsData";
export const RECON_TRANSACTION_FILE_UPLOAD = "reconTransactionFileUpload";
export const FETCH_RECONCILIATION_TRANSACTIONS = "fetchReconciationTransactions";
export const FETCH_UNRECONCILED_SETTLEMENT = "fetchUnReconciledSettlements";
export const DOWNLOAD_RECONCILIATION_TRANSACTIONS = "downloadReconciationTransactions";
export const DOWNLOAD_UNRECONCILED_SETTLEMENT = "downloadUnReconciledSettlements";
export const EXPORT_RECON_SUMMARY = "exportReconSummary";
export const FILE_UPLOAD = "fileUpload";
export const FETCH_RECON_METABASE_RESULT = "fetchReconMetabaseResult";


// Recon Analytics
export const FETCH_RECON_SUMMARY = "fetchReconSummary";
export const FETCH_RECON_SUMMARY_TABLE_RECORDS = "fetchReconSummaryTableRecords";
export const DOWNLOAD_RECON_SUMMARY_TABLE_RECORDS = "downloadReconSummaryTableRecords";

// Master Merchant
export const FETCH_MERCHANTS_LIST = "fetchMerchantList";
export const FETCH_MERCHANTS_PAGINATED_LIST = "fetchMerchantPaginatedList";
export const ACTIVE_INACTIVE_MERCHANT = "activeInactiveMerchant";
export const INVITE_MERCHANT_FOR_MANAGED = "inviteMerchantForManaged";
export const INVITE_MERCHANT_FOR_OWNED = "inviteMerchantForOwned";
export const FETCH_INVITED_MERCHANTS_LIST = "fetchInvitedMerchantList";

export const FETCH_PAYMENT_PAGE_LIST = "fetchPaymentPageList";
export const FETCH_PAYMENT_PAGE_DETAILS = "fetchPaymentPageDetails";
export const FETCH_PAYMENT_PAGE_TRANSACTIONS = "fetchPaymentPageTransactions";
export const INVITE_MERCHANT = "inviteMerchant";
export const EXPORT_PAYMENT_PAGE_TRANSACTIONS = "exportPaymentPageTransactions";
export const CANCEL_PAYMENT_PAGE = "cancelPaymentPage";
export const EXPORT_PAYMENT_PAGE_LIST = "exportPaymentPageList";
export const CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY = "checkPaymentPageCustomUrlAvailablity";

//Fee Management
export const FETCH_CHANNEL_LIST_FOR_FEE_MANAGEMENT = "fetchChannelListForFeeManagement";
export const FETCH_FEE_CONFIG_META = "fetchFeeConfiMeta";
export const FETCH_FEE_CONFIG_RECORDS = "fetchFeeConfiRecords";
export const FETCH_PAYMENT_METHODS_OF_PAYMENT_CHANNEL = "fetchPaymentMethodsOfPaymentChannel";
export const MERCHANT_RESEND_KYC = "merchantResendKyc";
export const MERCHANT_RESEND_INVITE = "merchantResendInvite";
export const DELETE_MERCHANT = "deleteMerchant";
export const FETCH_TOLERANCE_LEVEL = "fetchToleranceLevel";

//remittances dashboard
export const FETCH_REMITTANCES_TRANSACTION_STATS = "fetchRemittancesTransactionStats";
export const FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS = "fetchRemittancesTransactionDataForDoughnutCharts";
export const FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR = "fetchRemittancesTransactionStackedBar";
export const FETCH_SETTLEMENTS_SUMMARY_RESULTS = "fetchSettlementsSummaryResults";
export const FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY = "fetchSettlementsSummaryExpandBy";
export const FETCH_CROSS_BORDER_PAYMENT_METHODS = "fetchCrossBorderPaymentMethods";
export const CROSS_BORDER_UPDATE_ENABLE_AND_DEFAULT_STATUS = "crossBorderUpdateEnableAndDefaultStatus";
export const FETCH_REMITTANCE_SUMMARY = "fetchRemittanceSummary";
export const EXPORT_REMITTANCE_SUMMARY_INTO_EXCEL = "exportRemittanceSummaryIntoExcel";
export const FETCH_LEDGER = "fetchLedger";
export const DOWNLOAD_VA_EXCEL_LEDGER = "downloadExcelLedger";
// remittance bank account
export const FETCH_REMITTANCES_BANK_ACCOUNT = "fetchRemittancesBankAccount";
export const POST_REMITTANCES_BANK_ACCOUNT = "postRemittancesBankAccount";
export const UPDATE_REMITTANCES_BANK_ACCOUNT = "updateRemittancesBankAccount";
export const FETCH_BANK_LIST = "fetchBankList";

//subscription price
export const POST_SUBSCRIPTION_PRICE = "addSubscriptionPrice";
export const FETCH_SUBSCRIPTION_PRICE_LIST = "fetchSubscriptionPriceList";
export const DELETE_PRICE = "deletePrice";

// subscription
export const POST_SUBSCRIPTION = "addSubscription";
export const FETCH_SUBSCRIPTION_LIST = "fetchSubscriptionList";
export const DELETE_SUBSCRIPTION = "deleteSubscription";
export const FETCH_ALL_PRICE = "fetchAllPrice";
export const FETCH_SUBSCRIPTION_DETAILS = "fetchSubscriptionDetails";
export const FETCH_SUBSCRIPTION_TRANSACTIONS = "fetchSubscriptionTransactions";
export const POST_COLLECT_PAYMENT = "postCollectPayment";
//smart roiting
export const FETCH_TRANSACTION_ROUTES = "fetchTransactionRoutes";
export const POST_ROUTING_DETAILS = "postRoutingDetails";
export const UPDATE_ROUTING_DETAILS = "updateRoutingDetails";
export const DELETE_ROUTING_DETAILS = "deleteRoutingDetails";
export const FETCH_ALL_PAYMENT_CHANNEL_LIST = "fetchAllPaymentChannelList";
export const CHANGE_DEFAULT_ROUTING_DETAILS = "changeDefaultRoutingDetails";
//File Management
export const FETCH_FILE_MANAGEMENT_DATA = "fetchFileManagementData";
export const POST_FILE_MANAGEMENT_DATA = "postFileManagementData";
export const POST_FILE_CONFIG = "postFileConfig";
// Notifications
export const FETCH_NOTIFICATIONS = "fetchNotifications";
export const MARK_NOTIFICATION_READ = "markNotificationRead";
export const READ_ALL_NOTIFICATIONS = "readAllNotifications";

export const GENERATE_QR_CODE = "generateQRCode";
// Invoices
export const FETCH_INVOICES = "fetchInvoices";
export const EXPORT_INVOICES = "exportInvoices";
export const FETCH_INVOICES_TEMPLATES = "fetchInvoicesTemplates";
export const FETCH_INVOICES_DETAILS = "fetchInvoicesDetails";
export const FETCH_INVOICES_TEMPLATE_DETAILS = "fetchInvoiceTemplateDetails";
export const INVOICES_REMINDER = "invoicesReminder";
export const INVOICE_VOID = "invoicesVoid";
export const INVOICE_UNVOID = "invoicesUnvoid";
export const MARK_SCHEDULE_SENT = "markScheduleSent";
export const CREATE_INVOICES = "createInvoices";
export const CREATE_INVOICES_TEMPLATE = "createInvoicesTemplate";
export const UPDATE_INVOICES_TEMPLATE = "updateInvoicesTemplate";
export const UPDATE_INVOICES = "updateInvoices";
export const INVOICE_TIMELINE = "invoicesTimeline";
export const DELETE_INVOICE_TEMPLATE = "deleteInvoicesTemplate";
export const BULK_INVOICE_IMPORT = "bulkInvoiceImport";
