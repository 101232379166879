import Headers from '../headers';
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export const ReconciliationService = {
  fetchReconciliationResults,
  fetchIngestionSummary,
  fetchReconciliations,
  fetchReconciliationSummaryResults,
  fetchReconciliationSummaryForPspResults,
  fetchPspResultsForReconciliationSummary,
  uploadFile,
  fileUpload,
  createReconciliation,
  reconciliationDetails,
  reconciliationTransactions,
  reconciliationSettlement,
  fetchReconciliationTransactions,
  fetchUnReconciledSettlements,
  downloadReconciliationTransactions,
  downloadUnReconciledSettlements,
  getChannelListForMerchant,
  markRecon,
  getChannelList,
  exportFeeMismatchTransactions,
  exportReconSummery,
  getUserDataByPropertyType,
  getUserDataByPropertyTypeList,
  getReconMetabaseResult
};

function fetchReconciliationResults(iamportKey, payload) {

  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/ingestion/list`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchIngestionSummary(iamportKey, payload) {

  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  let queryParams = "";
  for (let key in payload) {
    if (queryParams != "") {
      queryParams += "&";
    }
    queryParams += (key + "=" + encodeURIComponent(payload[key]));
  }
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/ingestion/summary?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchReconciliations(iamportKey, payload) {

  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/list`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchReconciliationSummaryResults(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchReconciliationSummaryForPspResults(merchantUUId, iamportKey, pageIndex, pagesize, from_date, to_date, frequency, recon_type, environment) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify({ key:iamportKey,recon_type:recon_type,from_date:from_date,to_date:to_date,frequency:frequency, page: pageIndex, pagesize, environment }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchPspResultsForReconciliationSummary(merchantUUId, iamportKey, from_date, to_date, recon_type, environment) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify({ key:iamportKey,recon_type:recon_type,from_date:from_date,to_date:to_date, environment }),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/psp-wise/summary`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getChannelListForMerchant(iamportKey, environment, fileType, pageType, currency) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  let params = { environment, pageType };
  if(fileType){
    params.fileType = fileType; 
  }
  if(currency) {
    params.currency = currency; 
  }
  const queryParams = new URLSearchParams(params);
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/channels?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getChannelList(iamportKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/data-sources?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getUserDataByPropertyType(iamportKey, data) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };

  const queryParams = new URLSearchParams(data);
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/custom-data?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getUserDataByPropertyTypeList(iamportKey, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(data)
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/g/custom-data-v2`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchReconciliationTransactions(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/recon-dashboard-txns`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchUnReconciledSettlements(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/recon-dashboard-settlements`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function downloadReconciliationTransactions(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/export-recon-dashboard-txns`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = payload.status + "_transactions_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function downloadUnReconciledSettlements(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/export-recon-dashboard-settlements`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = payload.status + "_settlements_" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function uploadFile(merchantUUId, iamportKey, fileData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(false), 'X-Chaipay-Client-Key': iamportKey },
    body: fileData,
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/upload-file`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fileUpload(iamportKey, fileData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(false), 'X-Chaipay-Client-Key': iamportKey },
    body: fileData,
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/upload-file`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function createReconciliation(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    body: JSON.stringify(payload),
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/create-recon`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function reconciliationDetails(iamportKey, ref) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include'
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/list/${ref}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function reconciliationTransactions(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/recon-dashboard-txns`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function reconciliationSettlement(iamportKey, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/recon-dashboard-settlements`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function markRecon(body) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(body),
  };
  return fetch(`${CHAIPAY_API_URL}/api/recon/mark-reconciled`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        throw new Error(text);
      });
    }
  });
}

function exportFeeMismatchTransactions(iamportKey, environment, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(data)
  };
  const queryParams = new URLSearchParams({environment});
  return fetch(`${CHAIPAY_API_URL}/merchants/fee-mismatch-transactions?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "Reconciliation summary" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
  }

function exportReconSummery(iamportKey, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(data)
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/recon/psp-wise/summary/download`, requestOptions).then((response) => {
    if (response.ok) {
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "recon-summery" + (new Date().toISOString()) + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function getReconMetabaseResult(iamportKey, environment, currency, frequency) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ merchant_key:iamportKey,request_source:"portone-dev",environment, currency, frequency }),
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchants/recon/v3/analytics/dashboard`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}