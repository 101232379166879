import cloneDeep from 'lodash.clonedeep';
import { FileManagementService } from '../../helpers/backend/file_management.service';
import { FETCH_FILE_MANAGEMENT_DATA, POST_FILE_CONFIG, POST_FILE_MANAGEMENT_DATA } from '../actions.type';

import { FILE_MANAGEMENT_ADD_DATA_IN_STORE } from '../mutations.type';

export const state = {
    content: []
};

export const mutations = {
    [FILE_MANAGEMENT_ADD_DATA_IN_STORE](state, content) {

        state.content = content.map(((element, index) => {
            element.index = index;
            return element;
        }));
    }
};

export const actions = {
    [FETCH_FILE_MANAGEMENT_DATA]({ commit, rootState }, paymentChannel) {
        return new Promise((resolve, reject) => {
            const environment = rootState.auth.environment;
            const iamportKey = rootState.auth.currentUser.iamportKey;
            FileManagementService.fetchFileManagementRecords(iamportKey, environment, paymentChannel).then((response) => {
                commit(FILE_MANAGEMENT_ADD_DATA_IN_STORE, response.content)
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },
    [POST_FILE_MANAGEMENT_DATA]({ commit, rootState }, { formData, currentData }) {
        return new Promise((resolve, reject) => {
            let payload;
            const data = {
                environment: rootState.auth.environment,
                portone_key: rootState.auth.currentUser.iamportKey,
                engine_source: formData.display_name,
                file_prefix: formData.display_name,
                ...formData
            };

            if (currentData) {
                payload = cloneDeep(currentData);
            } else {
                payload = {
                    source_type: "PROVIDER",
                    input_header: {},
                    refund_header_mapping: {},
                    refund_input_header: {},
                    refund_offset: 0,
                    refund_sheet_number: -1,
                    parent_rfm_id: null,
                    created_by: "MERCHANT",
                    result_title_map: {}
                };
            }
            const finalPayload = { ...payload, ...data };

            FileManagementService.postFileManagementForm(finalPayload).then((response) => {
                resolve(response);
            }).catch((error) => {
                const errorObject = JSON.parse(error.message)
                commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            })
        })
    },
    [POST_FILE_CONFIG]({ commit, rootState }, { formData }) {
        return new Promise((resolve, reject) => {
            formData.append('environment', rootState.auth.environment);
            formData.append('portone_key', rootState.auth.currentUser.iamportKey);
            FileManagementService.postFileConfig(formData).then((response) => {
                resolve(response);
            }
            ).catch((error) => {
                const errorObject = JSON.parse(error.message)
                commit('notification/error', errorObject.Message, { root: true })
                reject(error);
            }
            )
        })
    }

};