export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
import Headers from '../headers';

export const PaymentMethodsService = {
  fetchCrossBorderPaymentMethods,
  crossBorderupdateEnableAndDefaultStatusForMethod
};
function fetchCrossBorderPaymentMethods(merchantUUID, iamportKey, environment) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment})
  return fetch(`${CHAIPAY_API_URL}/merchants/${iamportKey}/cross-border/pay-methods/countries?${queryParams}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function crossBorderupdateEnableAndDefaultStatusForMethod(merchantUUID, statusData, channelName) {
  // channel details vary from channel to channel
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    body: JSON.stringify( statusData ),
    credentials: 'include',
  };

  return fetch(`${CHAIPAY_API_URL}/merchants/${merchantUUID}/update-pay-methods/${channelName}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}