import { SubscriptionPriceService } from "../../helpers/backend/subscriptionprice.service";
import { DELETE_PRICE, FETCH_SUBSCRIPTION_PRICE_LIST, POST_SUBSCRIPTION_PRICE } from "../actions.type";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { SET_SUBSCRIPTION_PRICE_DATA_IN_STORE } from "../mutations.type";

export const state = {
    content: [],
    number_of_elements: 0,
    page: 0,
    size: 0,
    total_elements: 0,
    total_pages: 0,
}

export const mutations = {
    [SET_SUBSCRIPTION_PRICE_DATA_IN_STORE](state, data) {
      state.content = data.content;
      state.number_of_elements = data.number_of_elements;
      state.page = data.page;
      state.size = data.size;
      state.total_elements = data.total_elements;
      state.total_pages = data.total_pages;
    },
}

export const actions = {

    [POST_SUBSCRIPTION_PRICE](context, newEntityDetails) {
      return new Promise((resolve, reject) => {
        const environment = context.rootState.auth.environment;

        const { iamportKey, iamportSecret } = context.rootState.auth.currentUser;

        const merchantOrderRef = 'Price'+ new Date().getTime()

        const mainParams = "amount="+encodeURIComponent(newEntityDetails.amount)+"&client_key="+encodeURIComponent(iamportKey)+"&currency="+encodeURIComponent(newEntityDetails.currency)+"&frequency="+encodeURIComponent(newEntityDetails.frequency)+"&merchant_order_ref="+encodeURIComponent(merchantOrderRef);

        const hash = hmacSHA256(mainParams, iamportSecret);

        const signatureHash = Base64.stringify(hash);

        const formData = {
            ...newEntityDetails,
            "chaipay_key": iamportKey,
            "signature_hash": signatureHash,
            "merchant_order_ref": merchantOrderRef,
            "source": "checkout",
            environment: environment
        };

        SubscriptionPriceService.addSubscriptionPrice(formData).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    },

    [FETCH_SUBSCRIPTION_PRICE_LIST](context, payload) {
        return new Promise((resolve, reject) => {
          payload.environment = context.rootState.auth.environment;
          const merchantUuid = context.rootState.auth.currentUser.merchantUuid;
          const iamportKey = context.rootState.auth.currentUser.iamportKey;
          const isMasterMerchant = context.rootState.auth.currentUser.is_master_merchant;

          SubscriptionPriceService.fetchSubscriptionPriceList(merchantUuid, payload, isMasterMerchant, iamportKey).then((response) => {
            context.commit(SET_SUBSCRIPTION_PRICE_DATA_IN_STORE, response)
            resolve(response);
          }).catch((error) => {
            const errorObject = JSON.parse(error.message)
            context.commit('notification/error', errorObject.message, { root: true })
            reject(error);
          })
        })
    },

    [DELETE_PRICE](context, order_ref) {
      return new Promise((resolve, reject) => {
        const iamportKey = context.rootState.auth.currentUser.iamportKey;
        SubscriptionPriceService.deletePrice(order_ref, iamportKey).then((response) => {
          resolve(response);
        }).catch((error) => {
          const errorObject = JSON.parse(error.message)
          context.commit('notification/error', errorObject.message, { root: true })
          reject(error);
        })
      })
    }
}

export const namespaced = true;