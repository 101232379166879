import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueClipboard from 'vue-clipboard2'
import VueMask from 'v-mask'
import Notifications from 'vue-notification'
import UUID from 'vue-uuid'
import vco from 'v-click-outside'
import router from './router'
import store from '@/state/store'
import ToggleButton from 'vue-js-toggle-button'
import i18n from './i18n'
import '@/assets/scss/app.scss';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// import { initFirebaseBackend } from './helpers/firebase/authUtils';
import VueSocialSharing from 'vue-social-sharing'
import OneSignalVue from 'onesignal-vue'

// eslint-disable-next-line
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

VueClipboard.config.autoSetContainer = true

Vue.config.productionTip = false
Vue.use(OneSignalVue);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.use(Notifications);
Vue.use(VueMask)
Vue.use(ToggleButton)
Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(UUID);
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
      safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID,
      allowLocalhostAsSecureOrigin: true,
    });
  }
}).$mount('#app')
