import Headers from '../headers';

export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;
export const SubscriptionService = {
  addSubscription,
  fetchSubscriptionList,
  deleteSubscription,
  fetchAllPrice,
  fetchSubscription,
  fetchSubscriptionTransactions,
  postCollectPayment
};

function addSubscription(entityDetails) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify(entityDetails),
      credentials: 'include',
    };
    return fetch(`${CHAIPAY_API_URL}/merchant/subscription/createSubscription`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function fetchSubscriptionList(merchantUuid, payload, isMasterMerchant, iamportKey) {
    const requestOptions = {
      method: 'POST',
      headers: Headers.apiHeaders(),
      body: JSON.stringify(payload),
      credentials: 'include',
    };

    let URI = `merchant/subscription/${merchantUuid}/list`;
    if(isMasterMerchant) {
      URI = `master-merchant/${iamportKey}/merchants/subscription-list`;
    }

    return fetch(`${CHAIPAY_API_URL}/${URI}`, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => {
          if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
            window.location = window.location.origin + '/logout';
          }
          throw new Error(text);
        });
      }
    });
}

function deleteSubscription(orderRef, iamportKey) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/subscription/${orderRef}/status/cancelled`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchAllPrice(merchantUuid, environment) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
  };
  const queryParams = new URLSearchParams({environment})
  return fetch(`${CHAIPAY_API_URL}/merchant/subscription/price/${merchantUuid}/price-subscription-list?` + queryParams, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchSubscription(orderRef, iamportKey) {
  const requestOptions = {
    method: 'GET',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/subscription/${orderRef}`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function fetchSubscriptionTransactions(orderRef, payload, iamportKey) {
  const requestOptions = {
    method: 'POST',
    headers: { ...Headers.apiHeaders(), 'X-Chaipay-Client-Key': iamportKey },
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/subscription/${orderRef}/txns-records`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}

function postCollectPayment(payload) {
  const requestOptions = {
    method: 'POST',
    headers: Headers.apiHeaders(),
    credentials: 'include',
    body: JSON.stringify(payload)
  };
  return fetch(`${CHAIPAY_API_URL}/merchant/subscription/createDeduction`, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then(text => {
        if(response.status === 401 && JSON.parse(text).status_reason === 'INVALID_UNAUTHORIZED_JWT_TOKEN_ERROR') {
          window.location = window.location.origin + '/logout';
        }
        throw new Error(text);
      });
    }
  });
}