import { AccountService } from '../../helpers/backend/accounts.service';
// import * as cloneDeep from "lodash.clonedeep";
// import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js';

import {
  CREATE_SUB_USER,
  UPDATE_SUB_USER_DETAILS,
  FETCH_DETAILS_FOR_ALL_SUB_USERS,
  FETCH_SUB_USER_DETAILS_BY_ID,
  SETUP_OR_GENERATE_2FA_OTP,
  VERIFY_2FA_FOR_CURRENT_USER,
  // VERIFY_SUB_USER_PERMISSIONS
} from '../actions.type';

import {
  UPDATE_SUB_USER_DETAILS_IN_STORE,
  SET_AUTH_USER,
} from '../mutations.type';

export const state = {
  subUserData: [],
}

export const mutations = {
  [UPDATE_SUB_USER_DETAILS_IN_STORE](state, subUserData) {
    state.subUserData = subUserData;
  },
}

export const getters = {
  getSubUserDataFromStore(state) {
    return state.subUserData;
  },
}

export const actions = {

  [FETCH_DETAILS_FOR_ALL_SUB_USERS](context) {
    return new Promise((resolve, reject) => {
      const key = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      AccountService.fetchDetailsForAllSubUsers(key, environment).then((response) => {
        context.commit(UPDATE_SUB_USER_DETAILS_IN_STORE, response);
        resolve(response);
      }).catch((error) => {
        console.log(error)
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [CREATE_SUB_USER](context, subUserDetails) {
    return new Promise((resolve, reject) => {
      const chaiPayKey = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      subUserDetails['environment'] = environment;
      AccountService.createSubUser(chaiPayKey, subUserDetails).then((response) => {
        context.dispatch(`${FETCH_DETAILS_FOR_ALL_SUB_USERS}`)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [UPDATE_SUB_USER_DETAILS](context, {subUserID, subUserDetails}) {
    return new Promise((resolve, reject) => {
      const chaiPayKey = context.rootState.auth.currentUser.iamportKey;
      const environment = context.rootState.auth.environment;
      subUserDetails['environment'] = environment;
      AccountService.updateSubUserDetails(chaiPayKey, subUserID, subUserDetails).then((response) => {
        // context.dispatch(`${FETCH_DETAILS_FOR_ALL_SUB_USERS}`)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    });
  },

  [FETCH_SUB_USER_DETAILS_BY_ID](context) {
    return new Promise((resolve, reject) => {
      const chaiPayKey = context.rootState.auth.currentUser.iamportKey;
      const subUserUUID = context.rootState.auth.currentUser.subUserUuid;
      const environment = context.rootState.auth.environment;
      AccountService.fetchSubUserDetailsById(chaiPayKey, subUserUUID, environment).then((response) => {

        if(!response.is_active) {
          window.location = window.location.origin + '/logout';
        }

        let roles = response.roles.toString()

        let params = new URLSearchParams();
        params.append('iamportKey', response.iamportKey)
        params.append('iamportSecret', response.iamportSecret)
        params.append('roles', roles)
        let message = params.toString();

        let secure_hash = Base64.stringify(CryptoJS.HmacSHA256(message, response.subUserUuid));
        if(secure_hash !== response.secure_hash) {
          window.location = window.location.origin + '/pageNotFound';
        }

        context.commit(`auth/${SET_AUTH_USER}`, response, { root: true });
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [SETUP_OR_GENERATE_2FA_OTP](context, twoFADetails) {
    return new Promise((resolve, reject) => {
      const email_address = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      twoFADetails['environment'] = environment;
      AccountService.setupOrGenerate2FA(email_address, twoFADetails).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.message, { root: true })
        reject(error);
      })
    });
  },

  [VERIFY_2FA_FOR_CURRENT_USER](context, twoFADetails) {
    return new Promise((resolve, reject) => {
      const email_address = context.rootState.auth.currentUser.email_address;
      const environment = context.rootState.auth.environment;
      twoFADetails['environment'] = environment;
      AccountService.verify2FAForCurrentUser(email_address, twoFADetails).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.message, { root: true })
        reject(error);
      })
    });
  },

}


export const namespaced = true;