import * as cloneDeep from 'lodash.clonedeep';
import { PaymentLinksService } from '../../helpers/backend/paymentlinks.service';
// import moment from 'moment';
import {
  POST_PAYMENT_LINKS,
  FETCH_PAYMENT_LINKS,
  DELETE_PAYMENT_LINKS,
  FETCH_PAYMENT_LINK_DETAILS,
  FETCH_PAYMENT_LINK_TRANSACTIONS,
  CHECK_REF_LINK_PAYMENT_LINKS,
  NOTIFICATION_PAYMENT_LINKS,
  EXPORT_PAYMENT_LINK_LIST,
  GENERATE_QR_CODE,
  FETCH_SOURCE_LIST
} from '../actions.type';

import {
  ADD_PAYMENTLINKS_IN_STORE,
  SET_PAYMENT_LINKS_IN_STORE,
  UPDATE_PAYMENT_LINKS_DETAILS_IN_STORE,
  UPDATE_PAYMENT_LINKS_FETCHING_STATE,
  ADD_PAYMENT_LINK_DETAIL_IN_STATE,
  UPDATE_PAYMENT_LINK_TRANSACTIONS_IN_STATE,
  ADD_SOURCE_LIST_IN_STORE
} from '../mutations.type';

export const state = {
  isFetchingData: false,
  content: [],
  pageDetails:{},
  pageTransactions: [],
  total_elements: 0,
  total_pages: 0,
  size: 0,
  page: 0,
  number_of_elements: 0,
  sourceList:[]
}

export const mutations = {
  [SET_PAYMENT_LINKS_IN_STORE](state, entityDetails) {
    state.entityDetails = { ...state.entityDetails, ...entityDetails };
  },
  [ADD_PAYMENTLINKS_IN_STORE](state, newTransactionData) {
    state.isFetchingData = newTransactionData.isFetchingData;
    state.content = newTransactionData.content;
    state.total_elements = newTransactionData.total_elements;
    state.total_pages = newTransactionData.total_pages;
    state.size = newTransactionData.size;
    state.page = newTransactionData.page;
    state.number_of_elements = newTransactionData.number_of_elements;
  },
  [ADD_PAYMENT_LINK_DETAIL_IN_STATE](state, pageDetails) {
    state.pageDetails = pageDetails;
  },
  [UPDATE_PAYMENT_LINK_TRANSACTIONS_IN_STATE](state, transactions) {
    state.pageTransactions = transactions;
  },
  [UPDATE_PAYMENT_LINKS_DETAILS_IN_STORE](state, { entityKey, entityDetails }) {
    state.paymentLinks[entityKey] = entityDetails
    state.paymentLinks = cloneDeep(state.paymentLinks)
  },
  [UPDATE_PAYMENT_LINKS_FETCHING_STATE](state, isFetching) {
    state.isFetchingData = isFetching;
  },
  [ADD_SOURCE_LIST_IN_STORE](state, newSourceListData) {
		state.sourceList = newSourceListData;
	},
}

export const getters = {
  getPaymentLinksEntityDetails(state) {
    return state.entityDetails;
  }
}

export const actions = {

  [POST_PAYMENT_LINKS](context, newEntityDetails) {
    return new Promise((resolve, reject) => {
      const environment = context.rootState.auth.environment;
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      newEntityDetails['environment'] = environment;
      PaymentLinksService.addPaymentLink(merchantUUID, newEntityDetails).then((response) => {
        const entityDetails = response
        context.commit(SET_PAYMENT_LINKS_IN_STORE, entityDetails)
        context.commit('notification/success', "Payment Link Created successfully", { root: true })
        resolve(entityDetails);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.message, { root: true })
        reject(error);
      })
    })
  },
  [FETCH_SOURCE_LIST]({ commit, rootState }) {
		return new Promise((resolve, reject) => {
			const merchantUUID = rootState.auth.currentUser.merchantUuid;
			const environment = rootState.auth.environment;
			PaymentLinksService.getSourceList(merchantUUID, environment).then((response) => {
				commit(ADD_SOURCE_LIST_IN_STORE, response)
				resolve(response);
			}).catch((error) => {
				const errorObject = JSON.parse(error.message)
				commit('notification/error', errorObject.Message, { root: true })
				reject(error);
			})
		})
	},
  [DELETE_PAYMENT_LINKS](context, linkRef) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      PaymentLinksService.deletePaymentLink(merchantUUID, linkRef).then((response) => {
        console.log("response");
        console.log(response);
        context.commit('notification/success', "Payment Link Deleted successfully", { root: true })
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [NOTIFICATION_PAYMENT_LINKS](context, linkRef) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      PaymentLinksService.notificationPaymentLink(merchantUUID, linkRef).then((response) => {
        console.log("response");
        console.log(response);
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
  [CHECK_REF_LINK_PAYMENT_LINKS](context, refLink) {
    return new Promise((resolve, reject) => {
      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      PaymentLinksService.checkRefPaymentLink(merchantUUID, refLink).then((response) => {
        console.log("response");
        console.log(response);
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.error, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYMENT_LINK_DETAILS]({ commit, rootState }, { pageRef, submerchantKey }) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.is_master_merchant ? submerchantKey : rootState.auth.currentUser.iamportKey;
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      PaymentLinksService.fetchPaymentLinkDetails(merchantUUID, iamportKey, pageRef).then((response) => {
        commit(ADD_PAYMENT_LINK_DETAIL_IN_STATE, response)
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [FETCH_PAYMENT_LINK_TRANSACTIONS]({ commit, rootState }, { pageRef }) {
    commit(UPDATE_PAYMENT_LINKS_FETCHING_STATE, true);
    return new Promise((resolve, reject) => {
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      PaymentLinksService.fetchPaymentLinkTransactions(merchantUUID, iamportKey, environment, pageRef).then((response) => {
        commit(UPDATE_PAYMENT_LINKS_FETCHING_STATE, false)
        commit(UPDATE_PAYMENT_LINK_TRANSACTIONS_IN_STATE, response)
        resolve(response);
      }).catch((error) => {
        commit(UPDATE_PAYMENT_LINKS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [GENERATE_QR_CODE]({ commit, rootState }, { pageRef }) {
    return new Promise((resolve, reject) => {
      const iamportKey = rootState.auth.currentUser.iamportKey;
      PaymentLinksService.generateQRCode(iamportKey, pageRef).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },


  [FETCH_PAYMENT_LINKS]({ commit, rootState }, { pageIndex, pagesize, filters, isMasterMerchant, merchant_uuids }) {
    commit(UPDATE_PAYMENT_LINKS_FETCHING_STATE, true)
    return new Promise((resolve, reject) => {
      const merchantUUID = rootState.auth.currentUser.merchantUuid;
      const iamportKey = rootState.auth.currentUser.iamportKey;
      const environment = rootState.auth.environment;
      PaymentLinksService.fetchPaymentLinks(merchantUUID, iamportKey, pageIndex, pagesize, filters,isMasterMerchant, merchant_uuids, environment).then((response) => {
        if(response.content === null) {
					response.content = [];
				}
        const paylinks_content = response.content.map((paylinks) => {
          return {
            amount: (paylinks.amount).toLocaleString('en-US', { style: 'currency', currency: paylinks.currency }),
            buyerAddress1: paylinks.buyerAddress1 || "",
            description: paylinks.description || "",
            buyerAddress2: paylinks.buyerAddress2 || "",
            buyerCity: paylinks.buyerCity || "",
            created_at: paylinks.created_at,
            buyerCountry: paylinks.buyerCountry || "",
            buyerEmail: paylinks.customer_email_address || "",
            buyerName: paylinks.customer_name,
            buyerPhone: paylinks.customer_phone_number || "",
            merchantEmail: paylinks.merchant_email_address || "",
            cancel_url: paylinks.cancel_url || "",
            chaipaykey: paylinks.chaipaykey || "",
            countryCode: paylinks.countryCode || "",
            currency: paylinks.currency || "",
            expiry_hours: paylinks.expiry_hours || "",
            ip_address: paylinks.ip_address || "",
            link_ref: paylinks.link_ref || "",
            link: paylinks.link || "",
            merchant_back_url: paylinks.merchant_back_url || "",
            merchant_logo: paylinks.merchant_logo || "",
            merchant_name: paylinks.merchant_name || "",
            merchant_order_ref: paylinks.merchant_order_ref || "",
            merchant_promo_code: paylinks.merchant_promo_code || "",
            merchant_promo_discount: paylinks.merchant_promo_discount || "",
            merchant_shipping_charges: paylinks.merchant_shipping_charges || "",
            status: paylinks.status || "",
            source: paylinks.source || "",
            success_url: paylinks.success_url || "",
            user_configured_field1: paylinks.user_configured_field1 || "",
            user_configured_field2: paylinks.user_configured_field2 || "",
            user_configured_field3: paylinks.user_configured_field3 || "",
            user_configured_field4: paylinks.user_configured_field4 || "",
            user_configured_field5: paylinks.user_configured_field5 || "",
          };
        });

        const paymentLinksData = {
          content: paylinks_content,
          total_elements: response.total_elements,
          total_pages: response.total_pages,
          size: response.size,
          page: response.page,
          number_of_elements: response.number_of_elements,
        }
        commit(ADD_PAYMENTLINKS_IN_STORE, paymentLinksData)
        commit(UPDATE_PAYMENT_LINKS_FETCHING_STATE, false)
        resolve(paymentLinksData);
      }).catch((error) => {
        commit(UPDATE_PAYMENT_LINKS_FETCHING_STATE, false)
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [EXPORT_PAYMENT_LINK_LIST]({commit, rootState}, pageData) {
    return new Promise((resolve, reject) => {

      const iamportKey = rootState.auth.currentUser.iamportKey;
      const isMasterMerchant = rootState.auth.currentUser.is_master_merchant;
      pageData.environment = rootState.auth.environment;

      if(!pageData.filters.from) {
        pageData.filters.from = "1991-01-01 15:04:05";
      }

      if(!pageData.from) {
        pageData.from = "1991-01-01 15:04:05";
      }

      PaymentLinksService.exportPaymentLinkTransactions(iamportKey, pageData, isMasterMerchant).then((response) => {
        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        commit('notification/error', errorObject.status_message, { root: true })
        reject(errorObject);
      })
    })
  },

}

export const namespaced = true;
