import { AccountService } from '../../helpers/backend/accountstatement.service';
import * as cloneDeep from "lodash.clonedeep";

import {
  FETCH_ACCOUNT_STATEMENT,
  DOWNLOAD_VA_EXCEL_STATEMENT

} from '../actions.type';
import {
  UPDATE_ACCOUNTS_DATA_IN_STORE,
  DOWNLOAD_EXCEL_STATEMENT_IN_STORE,
  TOGGLE_ACCOUNT_STATEMENT_LOADER_IN_STORE
} from '../mutations.type';
export const state = {
  isFetchingData: false,

  accountStatement: [],
  total_elements: 0,
  total_pages: 0,
  size: 10,
  page: 1,
  number_of_elements: 0
}

export const mutations = {
  [TOGGLE_ACCOUNT_STATEMENT_LOADER_IN_STORE](state, toggle) {
    state.isFetchingData = toggle;
  },
  [UPDATE_ACCOUNTS_DATA_IN_STORE](state, accountStatement) {
    state.accountStatement = cloneDeep(accountStatement.content);
    state.total_elements = accountStatement.total_elements;
  },
  [DOWNLOAD_EXCEL_STATEMENT_IN_STORE](state, accountStatement) {
    console.log('accountStatement', accountStatement)
    state.accountStatement = cloneDeep(accountStatement)
  },
}

export const getters = {
  getAccountList(state) {
    return state.accountStatement;
  }
}
export const actions = {

  [FETCH_ACCOUNT_STATEMENT](context, { dates, account_no }) {
    return new Promise((resolve, reject) => {

      const merchantUUID = context.rootState.auth.currentUser.merchantUuid;
      context.commit(TOGGLE_ACCOUNT_STATEMENT_LOADER_IN_STORE, true);

      AccountService.fetchAccountStatement(merchantUUID, { dates, account_no }).then((response) => {

        context.commit(TOGGLE_ACCOUNT_STATEMENT_LOADER_IN_STORE, false);
        if (response.content.content === null) response.content.content = [];
        const statementContent = response.content.content.map((statement) => {
          statement.date = statement.created_at;
          return statement
        })
        response.content.content = statementContent;
        context.commit(UPDATE_ACCOUNTS_DATA_IN_STORE, response.content);
        resolve(response);
      }).catch((error) => {
        context.commit(TOGGLE_ACCOUNT_STATEMENT_LOADER_IN_STORE, false);
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },

  [DOWNLOAD_VA_EXCEL_STATEMENT](context, { dates, account_no }) {

    return new Promise((resolve, reject) => {
      const environment = context.rootState.auth.environment;
      AccountService.downloadExcelStatement(dates, account_no, environment).then((response) => {

        // context.dispatch(`${FETCH_ACCOUNT_STATEMENT}`);
        // context.commit('notification/success', "Virtual Account Created successfully", { root: true })
        // resolve(response);
        // context.commit(DOWNLOAD_EXCEL_STATEMENT_IN_STORE, response);

        resolve(response);
      }).catch((error) => {
        const errorObject = JSON.parse(error.message)
        context.commit('notification/error', errorObject.Message, { root: true })
        reject(error);
      })
    })
  },
}

export const namespaced = true;